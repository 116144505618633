/**
* Remove icon from external link styling
*/
/**
* Grid Mixin
*
* Applies a flexbox grid to a container element. All direct children will be modified to create a column based grid.
*/
/**
* 1.0 Root
*
* Top level styles every element should inherit.
*/
@font-face {
  font-display: swap;
  font-family: "HelveticaNeueLTStd77-BdCn";
  font-style: normal;
  font-weight: normal;
  src: url("../../dist/assets/fonts/22f5df0d-ec99-4f2e-a776-ca2bdf0df48a.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "HelveticaNowText";
  font-style: normal;
  font-weight: normal;
  src: url("../../dist/assets/fonts/709c6b31-9037-468f-88a6-4e659247799d.woff2") format("woff2"), url("../../dist/assets/fonts/709c6b31-9037-468f-88a6-4e659247799d.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "HelveticaNowText";
  font-style: normal;
  font-weight: 700;
  src: url("../../dist/assets/fonts/a1c4cdc5-3b7e-4bda-8b2e-776b84520ca3.woff") format("woff"); }

html {
  background-color: #fff;
  box-sizing: border-box;
  color: #333;
  font-family: 'HelveticaNowText', sans-serif;
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.625;
  overflow-wrap: break-word;
  word-wrap: break-word; }
  @media (min-width: 768px) {
    html {
      font-size: 16px; } }
  html *,
  html *::before,
  html *::after {
    box-sizing: inherit; }

/**
* 2.0 Layout
*
* Overall page layout.
*/
html {
  -ms-overflow-style: -ms-autohiding-scrollbar; }

body {
  overflow-x: hidden; }

.overflowHidden {
  height: 100%;
  overflow: hidden; }

.themeDefault {
  background-color: #fff; }

.themeDark {
  background-color: #333; }
  .themeDark,
  .themeDark h1, .themeDark h2, .themeDark h3, .themeDark h4, .themeDark h5, .themeDark h6, .themeDark .h1, .themeDark .h2, .themeDark .h3, .themeDark .h4, .themeDark .h5, .themeDark .h6 {
    color: #fff; }
  .themeDark ul > li::before {
    background-color: #fff; }
  .themeDark .highlighted {
    border-color: #fff;
    color: #fff; }

.themeLight {
  background-color: #666; }
  .themeLight,
  .themeLight h1, .themeLight h2, .themeLight h3, .themeLight h4, .themeLight h5, .themeLight h6, .themeLight .h1, .themeLight .h2, .themeLight .h3, .themeLight .h4, .themeLight .h5, .themeLight .h6 {
    color: #fff; }

.themeHero {
  background-color: #0aa5af; }
  .themeHero,
  .themeHero h1, .themeHero h2, .themeHero h3, .themeHero h4, .themeHero h5, .themeHero h6, .themeHero .h1, .themeHero .h2, .themeHero .h3, .themeHero .h4, .themeHero .h5, .themeHero .h6 {
    color: #fff; }

.flyntComponent {
  position: relative; }

.mainContent .flyntComponent:not(.themeDark):first-child {
  margin-top: 0;
  padding-top: 0; }

.mainContent .flyntComponent:not(.themeDark):last-child {
  margin-bottom: 0;
  padding-bottom: 0; }

.centerMaxWidthContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1292px;
  padding-left: 25px;
  padding-right: 25px; }
  @media (min-width: 768px) {
    .centerMaxWidthContainer {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 1292px;
      padding-left: 34px;
      padding-right: 34px; } }
  @media (min-width: 1280px) {
    .centerMaxWidthContainer {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 1292px;
      padding-left: 34px;
      padding-right: 34px; } }

.centerContentMaxWidth {
  margin: 0 auto;
  max-width: 1020px; }

/**
* 3.0 Base Style
*
* Styling all likely used HTML elements.
*/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #0aa5af;
  font-family: 'HelveticaNeueLTStd77-BdCn', sans-serif;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 0.9rem;
  margin-top: 0;
  text-transform: uppercase; }

h1,
.h1 {
  font-size: 1.875rem;
  line-height: 1;
  margin-bottom: 1rem;
  padding-top: 2.5rem; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 4rem; } }

h2,
.h2 {
  font-size: 1.75rem;
  line-height: 1;
  padding-top: 1.5rem; }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3.875rem; } }

h3,
.h3 {
  font-size: 1.5rem;
  padding-top: 1rem; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3.375rem; } }

h4,
.h4 {
  font-size: 1.31rem;
  padding-top: 1rem; }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 3rem; } }

h5,
.h5 {
  font-family: 'HelveticaNowText', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  padding-top: 0.8rem;
  text-transform: none; }
  @media (min-width: 768px) {
    h5,
    .h5 {
      font-size: 1.5rem; } }

h6,
.h6 {
  font-size: 1rem;
  line-height: 1.625rem;
  padding-top: 0.8rem; }
  @media (min-width: 768px) {
    h6,
    .h6 {
      font-size: 1.31rem; } }

.social-channels {
  margin-top: 40px; }

a {
  color: #003769;
  font-weight: 700;
  text-decoration: none; }
  @media (min-width: 768px) {
    a {
      transition: color 0.2s ease-out; }
      a:hover, a:focus {
        color: #0aa5af; }
      a:focus {
        outline: none; } }
  .themeDark a,
  .themeHero a,
  .themeLight a {
    color: #0aa5af; }
    @media (min-width: 768px) {
      .themeDark a:hover, .themeDark a:focus,
      .themeHero a:hover,
      .themeHero a:focus,
      .themeLight a:hover,
      .themeLight a:focus {
        color: #003769; } }
  @media (min-width: 768px) {
    .themeDark a:hover, .themeDark a:focus {
      color: #9ddbdf; } }
  a.icon-xing, a.icon-linkedin, a.icon-facebook, a.icon-instagram {
    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 15px; }
    a.icon-xing:hover, a.icon-linkedin:hover, a.icon-facebook:hover, a.icon-instagram:hover {
      color: #0aa5af; }
    a.icon-xing:before, a.icon-linkedin:before, a.icon-facebook:before, a.icon-instagram:before {
      content: '';
      background-image: url("../assets/icons/xing-sprite.svg");
      background-position: 0%;
      display: block;
      width: 34px;
      height: 34px;
      background-repeat: no-repeat;
      background-size: 200% 100%;
      margin-right: 10px; }
    a.icon-xing:after, a.icon-linkedin:after, a.icon-facebook:after, a.icon-instagram:after {
      content: '';
      background: none !important; }
  a.icon-linkedin:before {
    background-image: url("../assets/icons/linkedin-sprite.svg"); }
  a.icon-facebook:before {
    background-image: url("../assets/icons/facebook-sprite.svg"); }
  a.icon-instagram:before {
    background-image: url("../assets/icons/instagram-sprite.svg"); }
  a.no-icon:after {
    background-image: none;
    width: 0;
    margin: 0; }

[target=_blank] {
  display: inline;
  position: relative; }
  [target=_blank]::after {
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    content: '';
    display: inline-block;
    height: 13px;
    margin-left: 8px;
    width: 13px; }
    @media (min-width: 768px) {
      [target=_blank]::after {
        height: 14px;
        width: 12px; } }
  [target=_blank]::after {
    background-image: url("../assets/icons/red-arrow-external-sprite.svg"); }
  .themeDark [target=_blank]::after {
    background-image: url("../assets/icons/arrow-external-sprite-theme-dark.svg"); }
  @media (min-width: 768px) {
    [target=_blank]:hover::after, [target=_blank]:focus::after {
      background-position: 100%; } }
  .themeDark [target=_blank]::after,
  .themeHero [target=_blank]::after,
  .themeLight [target=_blank]::after {
    background-position: 100%; }
  @media (min-width: 768px) {
    .themeDark [target=_blank]:hover::after, .themeDark [target=_blank]:focus::after,
    .themeHero [target=_blank]:hover::after,
    .themeHero [target=_blank]:focus::after,
    .themeLight [target=_blank]:hover::after,
    .themeLight [target=_blank]:focus::after {
      background-position: 0; } }

dt {
  font-weight: 700; }

p,
.paragraph,
ul,
ol,
dl,
dd,
table,
figure {
  font-size: 0.875rem;
  margin: 0 0 1.2rem; }
  @media (min-width: 768px) {
    p,
    .paragraph,
    ul,
    ol,
    dl,
    dd,
    table,
    figure {
      font-size: 1rem;
      margin: 0 0 2.5rem; } }

ul,
ol {
  padding-left: 1.2rem; }
  @media (min-width: 768px) {
    ul,
    ol {
      left: 1.5rem; } }
  ul > li,
  ol > li {
    margin-bottom: 1rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0;
    margin-top: 1rem; }
    ul ul li,
    ul ol li,
    ol ul li,
    ol ol li {
      margin: 0.5rem 0; }
  ul li,
  ol li {
    font-size: 0.875rem; }
    @media (min-width: 768px) {
      ul li,
      ol li {
        font-size: 1rem; } }

ol {
  counter-reset: list-counter;
  list-style-type: none; }
  ol > li {
    counter-increment: list-counter;
    position: relative; }
    ol > li::before {
      content: counter(list-counter) ". ";
      font-size: 1em;
      left: -1.2em;
      position: absolute; }
      @media (min-width: 768px) {
        ol > li::before {
          left: -1.1em; } }

ul {
  list-style-type: none; }
  ul > li {
    position: relative; }
    ul > li::before {
      background-color: #333;
      content: '';
      height: 4px;
      left: -1.2em;
      position: absolute;
      top: 8px;
      width: 4px; }
      @media (min-width: 768px) {
        ul > li::before {
          left: -1.1em;
          top: 10px; } }

hr {
  background-color: #979797;
  border: 0;
  display: block;
  height: 1px;
  margin: 2.4rem 0; }

img {
  display: block;
  max-width: 100%;
  width: 100%; }

figcaption {
  font-size: 0.875rem;
  margin-top: 0.625rem; }

code {
  background: rgba(0, 0, 0, 0.05);
  padding: 0.1rem 0.3rem; }

blockquote,
.blockquote {
  border-left: 4px solid #003769;
  font-size: 1.12rem;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 1.55;
  margin: 2.4rem auto;
  max-width: 28rem;
  padding: 0.8rem 1.5rem; }
  blockquote p:last-child,
  .blockquote p:last-child {
    margin-bottom: 0; }

.scrollableTableWrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }

table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100% !important;
  max-width: 100%;
  width: 100% !important; }
  table tr,
  table td,
  table th {
    height: inherit !important; }

.table-wide table thead td {
  padding: 1rem 0; }

.table-wide table td {
  min-width: 100px;
  padding: 1.4rem 0; }

.table-narrow table thead td {
  padding: 1rem 0; }

.table-narrow table td {
  font-size: 0.75rem;
  padding: 0.8rem 0; }
  @media (min-width: 768px) {
    .table-narrow table td {
      font-size: 0.875rem; } }

td,
th {
  border: 0;
  padding: 0;
  text-align: inherit;
  vertical-align: middle; }

tr {
  border-bottom: 1px solid #979797; }
  tr:last-child {
    border-width: 2px; }

thead tr {
  border-bottom: 2px solid #979797; }
  thead tr td {
    color: #666;
    font-weight: bold; }

/**
* 3.1 Buttons
*
* Global button elements, including reset on standard button element.
*/
button {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-align: inherit; }

.button, .gform_wrapper .gform_footer {
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #003769;
  cursor: pointer;
  display: inline-block;
  font-family: 'HelveticaNeueLTStd77-BdCn', sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1;
  outline: 0;
  position: relative;
  text-align: inherit;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.2s ease;
  user-select: none; }
  .button::after, .gform_wrapper .gform_footer::after {
    background-position: 0;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    content: '';
    display: inline-block;
    height: 13px;
    margin-left: 8px;
    width: 16px; }
  .button::after, .gform_wrapper .gform_footer::after {
    background-image: url("../assets/icons/red-arrow-sprite.svg"); }
  @media (min-width: 768px) {
    .button, .gform_wrapper .gform_footer {
      font-size: 1.5rem; }
      .button::after, .gform_wrapper .gform_footer::after {
        background-size: auto;
        height: 17px;
        width: 22px; }
      .button:hover, .gform_wrapper .gform_footer:hover, .button:focus, .gform_wrapper .gform_footer:focus {
        color: #0aa5af; }
        .button:hover::after, .gform_wrapper .gform_footer:hover::after, .button:focus::after, .gform_wrapper .gform_footer:focus::after {
          background-position: 100%; } }
  .button:disabled, .gform_wrapper .gform_footer:disabled, .button--disabled {
    cursor: default;
    opacity: 0.65;
    pointer-events: none; }
  .button--external::after, .button[target=_blank]:not(.button-hasIcon)::after, .gform_wrapper .gform_footer[target=_blank]:not(.button-hasIcon)::after {
    background-image: url("../assets/icons/red-arrow-external-sprite.svg");
    height: 14px;
    width: 14px; }
    @media (min-width: 768px) {
      .button--external::after, .button[target=_blank]:not(.button-hasIcon)::after, .gform_wrapper .gform_footer[target=_blank]:not(.button-hasIcon)::after {
        height: 17px;
        right: 5px;
        width: 17px; } }
  @media (min-width: 768px) {
    .button--external:hover::after, .button--external:focus::after, .button[target=_blank]:not(.button-hasIcon):hover::after, .gform_wrapper .gform_footer[target=_blank]:not(.button-hasIcon):hover::after, .button[target=_blank]:not(.button-hasIcon):focus::after, .gform_wrapper .gform_footer[target=_blank]:not(.button-hasIcon):focus::after {
      background-position: 100%; } }
  .button--external.button--noText, .button[target=_blank]:not(.button-hasIcon).button--noText, .gform_wrapper .gform_footer[target=_blank]:not(.button-hasIcon).button--noText {
    padding: 0; }
  .button-hasIcon {
    align-items: center;
    background-color: #003769;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    padding: 0;
    transition: background-color 0.2s ease;
    width: 30px; }
    .button-hasIcon::before {
      background-repeat: no-repeat;
      background-size: 100%;
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .button-hasIcon::after {
      content: none; }
    @media (min-width: 768px) {
      .button-hasIcon {
        height: 34px;
        width: 34px; }
        .button-hasIcon:hover, .button-hasIcon:focus {
          background-color: #0aa5af; } }
    .button-hasIcon--close::before {
      background-image: url("../assets/icons/close.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
    .button-hasIcon--download::before {
      background-image: url("../assets/icons/download.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
    .button-hasIcon--expand::before {
      background-image: url("../assets/icons/expand.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
    .button-hasIcon--arrow-right-white::before {
      background-image: url("../assets/icons/arrow-right-white.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
    .button-hasIcon--arrow-external-white::before {
      background-image: url("../assets/icons/arrow-external-white.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
    .button-hasIcon--exclamation::before {
      background-image: url("../assets/icons/exclamation.svg");
      background-position: center;
      background-size: auto;
      height: 100%;
      opacity: 1;
      width: 100%; }
  .button--noText {
    color: transparent;
    display: inline-block;
    height: 17px;
    line-height: 0;
    padding: 0;
    text-indent: -99999px;
    vertical-align: middle;
    width: 17px; }
    .button--noText::before, .button--noText::after {
      display: block;
      margin: 0; }
  .themeDark .button, .themeDark .gform_wrapper .gform_footer, .gform_wrapper .themeDark .gform_footer,
  .themeHero .button,
  .themeHero .gform_wrapper .gform_footer,
  .gform_wrapper .themeHero .gform_footer,
  .themeLight .button,
  .themeLight .gform_wrapper .gform_footer,
  .gform_wrapper .themeLight .gform_footer {
    color: #0aa5af; }
    .themeDark .button:not(.button-hasIcon)::after, .themeDark .gform_wrapper .gform_footer:not(.button-hasIcon)::after, .gform_wrapper .themeDark .gform_footer:not(.button-hasIcon)::after,
    .themeHero .button:not(.button-hasIcon)::after,
    .themeHero .gform_wrapper .gform_footer:not(.button-hasIcon)::after,
    .gform_wrapper .themeHero .gform_footer:not(.button-hasIcon)::after,
    .themeLight .button:not(.button-hasIcon)::after,
    .themeLight .gform_wrapper .gform_footer:not(.button-hasIcon)::after,
    .gform_wrapper .themeLight .gform_footer:not(.button-hasIcon)::after {
      background-position: 100%; }
    .themeDark .button--hasIcon,
    .themeHero .button--hasIcon,
    .themeLight .button--hasIcon {
      background-color: #0aa5af; }
    @media (min-width: 768px) {
      .themeDark .button:hover, .themeDark .gform_wrapper .gform_footer:hover, .gform_wrapper .themeDark .gform_footer:hover, .themeDark .button:focus, .themeDark .gform_wrapper .gform_footer:focus, .gform_wrapper .themeDark .gform_footer:focus,
      .themeHero .button:hover,
      .themeHero .gform_wrapper .gform_footer:hover,
      .gform_wrapper .themeHero .gform_footer:hover,
      .themeHero .button:focus,
      .themeHero .gform_wrapper .gform_footer:focus,
      .gform_wrapper .themeHero .gform_footer:focus,
      .themeLight .button:hover,
      .themeLight .gform_wrapper .gform_footer:hover,
      .gform_wrapper .themeLight .gform_footer:hover,
      .themeLight .button:focus,
      .themeLight .gform_wrapper .gform_footer:focus,
      .gform_wrapper .themeLight .gform_footer:focus {
        color: #003769; }
        .themeDark .button:hover:not(.button-hasIcon)::after, .themeDark .gform_wrapper .gform_footer:hover:not(.button-hasIcon)::after, .gform_wrapper .themeDark .gform_footer:hover:not(.button-hasIcon)::after, .themeDark .button:focus:not(.button-hasIcon)::after, .themeDark .gform_wrapper .gform_footer:focus:not(.button-hasIcon)::after, .gform_wrapper .themeDark .gform_footer:focus:not(.button-hasIcon)::after,
        .themeHero .button:hover:not(.button-hasIcon)::after,
        .themeHero .gform_wrapper .gform_footer:hover:not(.button-hasIcon)::after,
        .gform_wrapper .themeHero .gform_footer:hover:not(.button-hasIcon)::after,
        .themeHero .button:focus:not(.button-hasIcon)::after,
        .themeHero .gform_wrapper .gform_footer:focus:not(.button-hasIcon)::after,
        .gform_wrapper .themeHero .gform_footer:focus:not(.button-hasIcon)::after,
        .themeLight .button:hover:not(.button-hasIcon)::after,
        .themeLight .gform_wrapper .gform_footer:hover:not(.button-hasIcon)::after,
        .gform_wrapper .themeLight .gform_footer:hover:not(.button-hasIcon)::after,
        .themeLight .button:focus:not(.button-hasIcon)::after,
        .themeLight .gform_wrapper .gform_footer:focus:not(.button-hasIcon)::after,
        .gform_wrapper .themeLight .gform_footer:focus:not(.button-hasIcon)::after {
          background-position: 0; }
        .themeDark .button:hover--hasIcon, .themeDark .gform_wrapper .gform_footer:hover--hasIcon, .gform_wrapper .themeDark .gform_footer:hover--hasIcon, .themeDark .button:focus--hasIcon, .themeDark .gform_wrapper .gform_footer:focus--hasIcon, .gform_wrapper .themeDark .gform_footer:focus--hasIcon,
        .themeHero .button:hover--hasIcon,
        .themeHero .gform_wrapper .gform_footer:hover--hasIcon,
        .gform_wrapper .themeHero .gform_footer:hover--hasIcon,
        .themeHero .button:focus--hasIcon,
        .themeHero .gform_wrapper .gform_footer:focus--hasIcon,
        .gform_wrapper .themeHero .gform_footer:focus--hasIcon,
        .themeLight .button:hover--hasIcon,
        .themeLight .gform_wrapper .gform_footer:hover--hasIcon,
        .gform_wrapper .themeLight .gform_footer:hover--hasIcon,
        .themeLight .button:focus--hasIcon,
        .themeLight .gform_wrapper .gform_footer:focus--hasIcon,
        .gform_wrapper .themeLight .gform_footer:focus--hasIcon {
          background-color: #003769; } }
  @media (min-width: 768px) {
    .themeDark .button:hover, .themeDark .gform_wrapper .gform_footer:hover, .gform_wrapper .themeDark .gform_footer:hover, .themeDark .button:focus, .themeDark .gform_wrapper .gform_footer:focus, .gform_wrapper .themeDark .gform_footer:focus {
      color: #9ddbdf; }
      .themeDark .button:hover:after, .themeDark .gform_wrapper .gform_footer:hover:after, .gform_wrapper .themeDark .gform_footer:hover:after, .themeDark .button:focus:after, .themeDark .gform_wrapper .gform_footer:focus:after, .gform_wrapper .themeDark .gform_footer:focus:after {
        background-image: url("../assets/icons/arrow-external-sprite-theme-dark.svg"); } }

.slider-button {
  align-items: center;
  background-color: #0aa5af;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  transition: background-color 0.2s ease, opacity 0.2s ease;
  width: 30px; }
  @media (min-width: 768px) {
    .slider-button {
      height: 34px;
      width: 34px; }
      .slider-button:hover, .slider-button:focus {
        background-color: #00508c; } }
  .slider-button--white {
    background-color: #fff; }
    .slider-button--white svg path {
      stroke: #003769; }
    @media (min-width: 768px) {
      .slider-button--white:hover, .slider-button--white:focus {
        background-color: #fff; }
        .slider-button--white:hover svg path, .slider-button--white:focus svg path {
          stroke: #0aa5af; } }
  .slider-button.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none; }

/**
* 3.2 Forms
*
* Global form elements, including structering wrapper elements.
*/
fieldset {
  border: 0;
  display: block;
  margin: 0 0 1rem;
  padding: 0;
  width: 100%; }
  @media (min-width: 768px) {
    fieldset {
      display: flex; } }
  @media (min-width: 1024px) {
    fieldset {
      display: flex;
      width: 85%; } }
  fieldset *:last-child {
    margin-bottom: 0; }

.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1rem;
  position: relative; }

legend {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.9375rem; }

label {
  color: #333;
  cursor: text;
  left: 16px;
  overflow: hidden;
  position: absolute;
  right: initial;
  text-align: left;
  text-overflow: ellipsis;
  top: 11px;
  transform: translateY(0) scale(1);
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
  will-change: transform; }
  @media (min-width: 768px) {
    label {
      top: 16px; } }

[type='text'],
[type='email'],
[type='search'],
[type='password'],
textarea,
select {
  appearance: none;
  background-color: #fafafa;
  border-bottom: 1px solid #999;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  min-height: 45px;
  padding: 16px 16px 6px;
  touch-action: manipulation;
  transition: all 160ms linear; }
  @media (min-width: 768px) {
    [type='text'],
    [type='email'],
    [type='search'],
    [type='password'],
    textarea,
    select {
      min-height: 56px; } }
  [type='text']::placeholder,
  [type='email']::placeholder,
  [type='search']::placeholder,
  [type='password']::placeholder,
  textarea::placeholder,
  select::placeholder {
    color: #fafafa;
    font-weight: 400;
    transition: inherit; }
    [type='text']::placeholder + label,
    [type='email']::placeholder + label,
    [type='search']::placeholder + label,
    [type='password']::placeholder + label,
    textarea::placeholder + label,
    select::placeholder + label {
      cursor: text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  [type='text']:focus,
  [type='email']:focus,
  [type='search']:focus,
  [type='password']:focus,
  textarea:focus,
  select:focus {
    background-color: #f2f2f2;
    outline: 0; }
    [type='text']:focus + label,
    [type='email']:focus + label,
    [type='search']:focus + label,
    [type='password']:focus + label,
    textarea:focus + label,
    select:focus + label {
      cursor: pointer;
      pointer-events: all; }
  [type='text']:focus::placeholder,
  [type='email']:focus::placeholder,
  [type='search']:focus::placeholder,
  [type='password']:focus::placeholder,
  textarea:focus::placeholder,
  select:focus::placeholder {
    color: rgba(242, 242, 242, 0.2);
    opacity: 1; }
  [type='text']:placeholder-shown + label,
  [type='text'].placeholder-shown + label,
  [type='email']:placeholder-shown + label,
  [type='email'].placeholder-shown + label,
  [type='search']:placeholder-shown + label,
  [type='search'].placeholder-shown + label,
  [type='password']:placeholder-shown + label,
  [type='password'].placeholder-shown + label,
  textarea:placeholder-shown + label,
  textarea.placeholder-shown + label,
  select:placeholder-shown + label,
  select.placeholder-shown + label {
    color: #333;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(0) scale(1);
    white-space: nowrap; }
  [type='text']:not(:placeholder-shown) + label,
  [type='email']:not(:placeholder-shown) + label,
  [type='search']:not(:placeholder-shown) + label,
  [type='password']:not(:placeholder-shown) + label,
  textarea:not(:placeholder-shown) + label,
  select:not(:placeholder-shown) + label {
    color: #999;
    transform: translateY(-40%) scale(0.75); }
    @media (min-width: 768px) {
      [type='text']:not(:placeholder-shown) + label,
      [type='email']:not(:placeholder-shown) + label,
      [type='search']:not(:placeholder-shown) + label,
      [type='password']:not(:placeholder-shown) + label,
      textarea:not(:placeholder-shown) + label,
      select:not(:placeholder-shown) + label {
        transform: translateY(-50%) scale(0.75); } }
    [type='text']:not(:placeholder-shown) + label .gfield_required,
    [type='email']:not(:placeholder-shown) + label .gfield_required,
    [type='search']:not(:placeholder-shown) + label .gfield_required,
    [type='password']:not(:placeholder-shown) + label .gfield_required,
    textarea:not(:placeholder-shown) + label .gfield_required,
    select:not(:placeholder-shown) + label .gfield_required {
      color: #999; }
  [type='text']:focus + label,
  [type='text'].with-value + label,
  [type='email']:focus + label,
  [type='email'].with-value + label,
  [type='search']:focus + label,
  [type='search'].with-value + label,
  [type='password']:focus + label,
  [type='password'].with-value + label,
  textarea:focus + label,
  textarea.with-value + label,
  select:focus + label,
  select.with-value + label {
    color: #999;
    transform: translateY(-40%) scale(0.75); }
    @media (min-width: 768px) {
      [type='text']:focus + label,
      [type='text'].with-value + label,
      [type='email']:focus + label,
      [type='email'].with-value + label,
      [type='search']:focus + label,
      [type='search'].with-value + label,
      [type='password']:focus + label,
      [type='password'].with-value + label,
      textarea:focus + label,
      textarea.with-value + label,
      select:focus + label,
      select.with-value + label {
        transform: translateY(-50%) scale(0.75); } }
    [type='text']:focus + label .gfield_required,
    [type='text'].with-value + label .gfield_required,
    [type='email']:focus + label .gfield_required,
    [type='email'].with-value + label .gfield_required,
    [type='search']:focus + label .gfield_required,
    [type='search'].with-value + label .gfield_required,
    [type='password']:focus + label .gfield_required,
    [type='password'].with-value + label .gfield_required,
    textarea:focus + label .gfield_required,
    textarea.with-value + label .gfield_required,
    select:focus + label .gfield_required,
    select.with-value + label .gfield_required {
      color: #999; }

textarea {
  min-height: 150px;
  padding: 10px 0 0;
  resize: vertical;
  width: 100%; }

select {
  background-image: url("data:image/svg+xml;charset=utf8,  %3Csvg width='16' height='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M-4-8h24v24H-4z'/%3E%3Cpath stroke='rgb(10, 165, 175)' stroke-width='2' d='M1.212.8L8 7.588 14.788.8'/%3E%3C/g%3E%3C/svg%3E");
  background-position: calc(100% - 18px) center;
  background-repeat: no-repeat;
  background-size: 16px;
  height: 45px;
  padding-bottom: 10px;
  padding-right: 45px;
  padding-top: 10px; }
  @media (min-width: 768px) {
    select {
      height: 56px; } }
  select::-ms-expand {
    display: none; }

[type='checkbox'],
[type='radio'] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1px; }
  [type='checkbox'], [type='checkbox']:hover, [type='checkbox']:focus,
  [type='radio'],
  [type='radio']:hover,
  [type='radio']:focus {
    border-color: #979797;
    outline: 0; }
  [type='checkbox'] + label,
  [type='radio'] + label {
    display: inline-block;
    max-width: 100%;
    padding: 0.5rem 0 0.5rem 2rem;
    position: relative;
    width: 100%; }
    [type='checkbox'] + label::before,
    [type='radio'] + label::before {
      background: #fafafa;
      border: 1px solid #999;
      border-radius: 0;
      content: '';
      display: block;
      height: 22px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: border-color 0.2s ease, background-color 0.2s ease;
      width: 22px; }
  [type='checkbox']:checked + label::before,
  [type='radio']:checked + label::before {
    background-color: #003769;
    box-shadow: inset 0 0 0 3px #fafafa; }

[type='radio'] + label::before {
  border-radius: 50%; }

#ui-datepicker-div {
  background: #fff; }
  #ui-datepicker-div .ui-state-default {
    color: #000000;
    font-weight: normal; }
  #ui-datepicker-div .ui-state-highlight {
    color: #0aa5af; }

.ui-datepicker-prev,
.ui-datepicker-next {
  display: none; }

.ui-datepicker-month,
.ui-datepicker-year {
  cursor: pointer; }

.ui-datepicker-calendar tr {
  border-bottom-width: 1px; }

.gform_wrapper .screen-reader-text,
.gform_wrapper label.hidden_sub_label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important; }

.gform_wrapper form {
  position: relative; }

.gform_wrapper .gform_body {
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .gform_wrapper .gform_body {
      padding-bottom: 40px; } }

.gform_wrapper .gform_fields {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }
  .gform_wrapper .gform_fields > li::before {
    content: none; }
  .gform_wrapper .gform_fields .gfield {
    margin: 0;
    padding: 0;
    width: 100%; }
    .gform_wrapper .gform_fields .gfield input.datepicker_with_icon {
      width: 100%; }
    @media (min-width: 768px) {
      .gform_wrapper .gform_fields .gfield {
        margin: 10px 0; }
        .gform_wrapper .gform_fields .gfield.left_half {
          padding: 0 15px 0 0;
          width: 50%; }
        .gform_wrapper .gform_fields .gfield.right_half {
          padding: 0 0 0 15px;
          width: 50%; } }
    .gform_wrapper .gform_fields .gfield.hidden_label .gfield_label {
      display: none; }
    .gform_wrapper .gform_fields .gfield.hidden_label .gfield_required {
      display: none; }
    .gform_wrapper .gform_fields .gfield.file_input .gfield_label {
      background: #fafafa;
      border-bottom: 1px solid #999;
      cursor: pointer;
      display: block;
      height: 55px;
      left: inherit;
      padding: 16px 16px 6px;
      position: relative;
      top: inherit;
      transform: none;
      width: 100%; }
      .gform_wrapper .gform_fields .gfield.file_input .gfield_label:hover::after {
        background-color: #0aa5af; }
      .gform_wrapper .gform_fields .gfield.file_input .gfield_label::after {
        background-color: #003769;
        background-image: url("../assets/icons/upload.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        bottom: 10px;
        content: '';
        height: 34px;
        position: absolute;
        right: 10px;
        transition: background-color 0.2s ease;
        width: 34px; }
    .gform_wrapper .gform_fields .gfield.gfield_error {
      background-color: transparent;
      border: 0; }
      .gform_wrapper .gform_fields .gfield.gfield_error .gfield_label {
        color: #003769;
        margin: 0; }
      .gform_wrapper .gform_fields .gfield.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
      .gform_wrapper .gform_fields .gfield.gfield_error select,
      .gform_wrapper .gform_fields .gfield.gfield_error textarea {
        border: 0;
        border-bottom: 1px solid #999; }
      .gform_wrapper .gform_fields .gfield.gfield_error .validation_message {
        color: #003769;
        font-size: 0.75rem;
        font-weight: normal;
        padding: 2px 0 0 16px; }
      .gform_wrapper .gform_fields .gfield.gfield_error .gfield_required {
        color: #003769; }
      .gform_wrapper .gform_fields .gfield.gfield_error .ginput_container {
        margin-bottom: 0;
        margin-top: 0;
        position: relative; }
        .gform_wrapper .gform_fields .gfield.gfield_error .ginput_container::after {
          background-image: url("../assets/icons/exclamation.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          content: '';
          height: 30px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 30px; }
        .gform_wrapper .gform_fields .gfield.gfield_error .ginput_container.ginput_container_textarea::after {
          top: 25px; }
        .gform_wrapper .gform_fields .gfield.gfield_error .ginput_container.ginput_container_radio::after, .gform_wrapper .gform_fields .gfield.gfield_error .ginput_container.ginput_container_consent::after {
          content: none; }
      .gform_wrapper .gform_fields .gfield.gfield_error input.datepicker_with_icon.small,
      .gform_wrapper .gform_fields .gfield.gfield_error input.datepicker_with_icon.medium,
      .gform_wrapper .gform_fields .gfield.gfield_error input.datepicker_with_icon.large {
        width: calc(100% - 32px); }
      .gform_wrapper .gform_fields .gfield.gfield_error input.datepicker.datepicker_with_icon {
        margin-right: 4px !important;
        display: -moz-inline-stack;
        display: inline-block; }
  .gform_wrapper .gform_fields input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
    padding: 16px 16px 6px; }
  .gform_wrapper .gform_fields select {
    height: 45px;
    padding: 10px 45px 10px 16px;
    width: 100%; }
    @media (min-width: 768px) {
      .gform_wrapper .gform_fields select {
        height: 56px; } }
  .gform_wrapper .gform_fields textarea {
    padding: 22px 16px 6px; }
  .gform_wrapper .gform_fields img.ui-datepicker-trigger {
    width: 17px !important;
    height: 16px !important;
    position: absolute;
    right: 20px;
    top: 20px; }

.gform_wrapper .gfield_required {
  color: #333;
  display: inline;
  margin-left: 0; }

.gform_wrapper .top_label .gfield_label {
  font-weight: normal; }

.gform_wrapper .top_label .medium,
.gform_wrapper .top_label .small {
  width: 100%; }

.gform_wrapper .gfield_radio {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0;
  width: 100%; }
  .gform_wrapper .gfield_radio > li::before {
    content: none; }
  .gform_wrapper .gfield_radio li {
    margin: 0 0 0 2rem; }
    .gform_wrapper .gfield_radio li:first-child {
      margin-left: 0; }
    .gform_wrapper .gfield_radio li label {
      color: #333;
      margin: 0; }

.gform_wrapper .ginput_container_consent .gfield_consent_label {
  cursor: pointer;
  font-size: 0.75rem;
  left: inherit;
  position: relative;
  top: inherit; }

.gform_wrapper .ginput_container_select {
  margin-bottom: 1rem; }

.gform_wrapper .ginput_container_fileupload [type=file] {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.gform_wrapper .ginput_container_fileupload .screen-reader-text {
  font-size: 0.75rem; }

.gform_wrapper .ginput_container_fileupload [type=file] {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.gform_wrapper .ginput_container_fileupload .screen-reader-text {
  font-size: 0.75rem; }

.gform_wrapper .ginput_container_radio {
  margin-bottom: 20px; }
  .gform_wrapper .ginput_container_radio label {
    left: inherit;
    position: relative;
    top: inherit; }

.gform_wrapper .gform_footer {
  margin: 0;
  padding: 0;
  text-align: left;
  width: auto; }
  @media (min-width: 768px) {
    .gform_wrapper .gform_footer {
      bottom: 0;
      margin: 0;
      position: absolute;
      right: 0;
      text-align: right; } }
  .gform_wrapper .gform_footer .gform_button {
    padding: 0 5px 0 0; }
    .gform_wrapper .gform_footer .gform_button::after {
      content: none; }
  .gform_wrapper .gform_footer::after {
    margin: 0; }

.gform_wrapper .validation_error {
  border: 0;
  color: #003769;
  font-size: 1rem;
  font-weight: normal; }

.gform_wrapper .gfield_best_before_date .gfield_label_smaller,
.gform_wrapper .gfield_best_before_date .gfield_label_smaller span {
  transform: translateY(-50%) scale(0.75);
  color: #999; }

.gform_validation_error .gfield_best_before_date .gfield_label,
.gform_validation_error .gfield_best_before_date .gfield_label span {
  transform: translateY(-50%) scale(0.75);
  color: #999; }

.gform_delete {
  display: inline-block;
  max-width: inherit;
  width: auto; }

.gform_ajax_spinner {
  display: none;
  left: 0;
  margin: 0 auto;
  max-width: none;
  position: absolute;
  right: 0;
  width: auto; }

/**
* 3.4 Custom text styles
*/
.largeText {
  font-size: 1rem; }
  @media (min-width: 768px) {
    .largeText {
      font-size: 1.31rem; } }

.smallText {
  font-size: 0.75rem; }
  @media (min-width: 768px) {
    .smallText {
      font-size: 0.875rem; } }

.legalText {
  font-size: 0.75rem; }

.overlineText {
  background-color: #0aa5af;
  color: #fff;
  display: inline-block;
  font-family: 'HelveticaNeueLTStd77-BdCn', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 0;
  padding: 5px 8px 5px 5px;
  text-transform: uppercase; }
  .overlineText + p {
    margin-top: 1.2rem; }
    @media (min-width: 768px) {
      .overlineText + p {
        margin-top: 2.5rem; } }
  @media (min-width: 768px) {
    .overlineText {
      font-size: 1.5rem;
      padding: 6px 15px 5px 10px; } }

.highlighted {
  background: none;
  border-bottom: 1px solid #979797;
  border-top: 1px solid #979797;
  line-height: 1.5;
  margin: 2.13rem 0;
  padding: 1.87rem 0.94rem; }
  .highlighted,
  .highlighted h1, .highlighted h2, .highlighted h3, .highlighted h4, .highlighted h5, .highlighted h6, .highlighted .h1, .highlighted .h2, .highlighted .h3, .highlighted .h4, .highlighted .h5, .highlighted .h6 {
    color: #666; }
  @media (min-width: 768px) {
    .highlighted {
      padding: 2.13rem; } }
  @media (min-width: 1024px) {
    .highlighted {
      margin: 3.85rem 0;
      padding: 2.5rem 4.25rem; } }

.admin-bar .ab-top-menu {
  left: 0; }
  .admin-bar .ab-top-menu ul {
    left: 0; }
  .admin-bar .ab-top-menu [target=_blank]::before, .admin-bar .ab-top-menu [target=_blank]::after {
    content: none; }

.lazyload,
.lazyloading {
  opacity: 0; }

img.lazyloaded {
  opacity: 1;
  transition: opacity 400ms; }

.figure {
  background-color: #f2f2f2; }

@media all and (-ms-high-contrast: none) {
  .swiper-wrapper {
    opacity: 0.99; } }

.tooltip-wrapper {
  position: absolute;
  top: 13px;
  left: -5px;
  display: none; }
  .tooltip-wrapper.is-visible {
    display: block; }

.tooltip-bg {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  margin-left: 7px;
  position: relative;
  display: inline-block;
  line-height: 1 !important;
  cursor: pointer; }
  .tooltip-bg.has-icon {
    width: 25px;
    height: 25px; }

.tooltip-bg > span {
  line-height: 1.1 !important;
  padding: 5px;
  z-index: 1 !important; }

.tooltip-bg > i {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1; }

.fa-info-circle:before {
  content: '';
  display: block;
  background: url("../assets/icons/icon-question.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px; }

.tooltip-bg > span:not(.tooltip-desc) {
  background-color: black;
  color: white;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: max-content;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  left: calc(100% - 20px);
  bottom: 100%;
  background: #0aa5af; }

.tooltip-bg > span:not(.tooltip-desc):after {
  content: '';
  display: inline-block;
  border: 5px solid;
  position: absolute;
  transition: all 0.3s ease-in-out;
  border-color: #0aa5af transparent transparent transparent;
  top: 100%;
  left: 6px;
  transition: all 0.3s ease-in-out; }

.tooltip-wrapper .tooltip-bg > span.tooltip-desc:hover ~ span,
.tooltip-wrapper .tooltip-bg > i:hover ~ span {
  opacity: 1;
  bottom: calc(100% + 10px);
  transition: all 0.3s ease-in-out;
  visibility: visible; }

.tooltip-image img {
  max-width: 280px; }

@media (min-width: 768px) {
  .gfield--datepicker-default-icon label {
    left: 32px; }
  .tooltip-wrapper {
    left: 0;
    top: -25px; }
  .tooltip-image img {
    max-width: none; } }

[is='flynt-accordion-vertical'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-accordion-vertical'] {
      margin: 68px 0; } }
  [is='flynt-accordion-vertical'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 25px;
    padding-right: 25px; }
  [is='flynt-accordion-vertical'] .preContent {
    margin-bottom: 1.5rem;
    max-width: 1020px; }
    [is='flynt-accordion-vertical'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-accordion-vertical'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-accordion-vertical'] .preContent {
        margin-bottom: 3.4rem; } }
  [is='flynt-accordion-vertical'] .content {
    margin: 0 auto;
    max-width: 1020px; }
  [is='flynt-accordion-vertical'] .accordion {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    list-style: none;
    margin: 0;
    padding: 0; }
    [is='flynt-accordion-vertical'] .accordion > li::before {
      content: none; }
  [is='flynt-accordion-vertical'] .panel {
    border-top: 1px solid #999;
    margin: 0;
    padding: 0; }
    [is='flynt-accordion-vertical'] .panel:last-child {
      border-bottom: 1px solid #999; }
    [is='flynt-accordion-vertical'] .panel-trigger {
      cursor: pointer;
      font-size: 0.875rem;
      margin: 0;
      outline: 0;
      padding: 10px 30px 10px 0;
      position: relative;
      width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-accordion-vertical'] .panel-trigger {
          font-size: 1.31rem;
          padding: 17px 30px 17px 0; } }
      [is='flynt-accordion-vertical'] .panel-trigger::after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='20' height='12' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.592 1.577l8.485 8.485 8.485-8.485' stroke='rgb(0, 55, 105)' stroke-width='2' fill='none' fill-rule='evenodd'/%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        width: 18px; }
        @media (min-width: 768px) {
          [is='flynt-accordion-vertical'] .panel-trigger::after {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='16'%3e%3cpath d='M1.272 1L14 13.728 26.728 1' stroke='rgb(0, 55, 105)' stroke-width='3' fill='none' fill-rule='evenodd'/%3e%3c/svg%3e");
            width: 28px; } }
      [is='flynt-accordion-vertical'] .panel-trigger[aria-expanded='true']::after {
        transform: rotateX(180deg); }
    [is='flynt-accordion-vertical'] .panel-content {
      display: none;
      margin: 0;
      padding: 0; }
    [is='flynt-accordion-vertical'] .panel-revealer {
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        [is='flynt-accordion-vertical'] .panel-revealer {
          margin-bottom: 32px; } }
    [is='flynt-accordion-vertical'] .panel:first-child .panel-content {
      display: block; }
    [is='flynt-accordion-vertical'] .panel-inner {
      color: #666;
      font-size: 0.875rem;
      padding: 20px 0; }
      [is='flynt-accordion-vertical'] .panel-inner > :first-child:not(.overlineText) {
        margin-top: 0;
        padding-top: 0; }
      [is='flynt-accordion-vertical'] .panel-inner > :last-child:not(.overlineText) {
        margin-bottom: 0;
        padding-bottom: 0; }
      @media (min-width: 768px) {
        [is='flynt-accordion-vertical'] .panel-inner {
          color: inherit;
          font-size: inherit;
          padding: 20px 34px 60px; } }
  [is='flynt-accordion-vertical'] .parallax {
    will-change: transform; }
    [is='flynt-accordion-vertical'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
  [is='flynt-accordion-vertical'] [is='flynt-block-social-share'] {
    margin-top: 0; }
    [is='flynt-accordion-vertical'] [is='flynt-block-social-share'] .centerMaxWidthContainer {
      padding: 0; }
    [is='flynt-accordion-vertical'] [is='flynt-block-social-share'] .social-wrap {
      border-top: 0;
      transition: none; }

[is='flynt-block-chronical'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-chronical'] {
      margin: 68px 0; } }
  [is='flynt-block-chronical'] .centerMaxWidthContainer {
    padding-bottom: 45px; }
  [is='flynt-block-chronical'] .preContent {
    margin-bottom: 1.5rem;
    max-width: 450px; }
    [is='flynt-block-chronical'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-block-chronical'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .preContent {
        margin-bottom: 3.4rem;
        max-width: 1020px; } }
  [is='flynt-block-chronical'] .figure {
    margin: 0; }
  [is='flynt-block-chronical'] .timeline {
    margin: 50px auto 0;
    max-width: 450px;
    position: relative; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .timeline {
        margin-left: -34px;
        margin-right: -34px;
        margin-top: 0;
        max-width: none; } }
    @media (min-width: 1280px) {
      [is='flynt-block-chronical'] .timeline {
        margin-left: -68px;
        margin-right: -68px; } }
    [is='flynt-block-chronical'] .timeline::before {
      background: linear-gradient(to bottom, #fff 0%, #f2f2f2 2%, #f2f2f2 50%, #f2f2f2 98%, #fff 100%);
      content: '';
      display: block;
      height: calc(100% + 90px);
      left: 50%;
      margin-left: -3px;
      position: absolute;
      top: -45px;
      width: 6px;
      z-index: -1; }
      @media (min-width: 768px) {
        [is='flynt-block-chronical'] .timeline::before {
          background: linear-gradient(to bottom, #fff 0%, #f2f2f2 5%, #f2f2f2 50%, #f2f2f2 95%, #fff 100%);
          height: calc(100% + 50px);
          top: -25px; } }
    [is='flynt-block-chronical'] .timeline::after {
      clear: both;
      content: '';
      display: block; }
  [is='flynt-block-chronical'] .event {
    opacity: 0;
    padding: 0 0 30px;
    width: 100%; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event {
        clear: left;
        display: flex;
        flex-direction: column-reverse;
        float: left;
        padding: 0 34px 34px;
        width: 50%; }
        [is='flynt-block-chronical'] .event:nth-child(even) {
          clear: right;
          float: right; }
        [is='flynt-block-chronical'] .event.extra-margin .event-caption::before, [is='flynt-block-chronical'] .event.extra-margin .event-caption::after {
          margin-top: 50px; } }
    @media (min-width: 1280px) {
      [is='flynt-block-chronical'] .event {
        padding: 0 68px 34px; } }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event:nth-child(2) {
        margin-top: 34px; } }
    @media (min-width: 1024px) {
      [is='flynt-block-chronical'] .event:nth-child(2) {
        margin-top: 68px; } }
    [is='flynt-block-chronical'] .event-caption {
      padding: 25px;
      position: relative;
      z-index: 2; }
      [is='flynt-block-chronical'] .event-caption > :first-child:not(.overlineText) {
        margin-top: 0;
        padding-top: 0; }
      [is='flynt-block-chronical'] .event-caption > :last-child:not(.overlineText) {
        margin-bottom: 0;
        padding-bottom: 0; }
      @media (min-width: 768px) {
        [is='flynt-block-chronical'] .event-caption {
          padding: 34px; } }
      [is='flynt-block-chronical'] .event-caption:not(.themeDark) {
        background: #f2f2f2; }
        [is='flynt-block-chronical'] .event-caption:not(.themeDark)::before {
          border-top-color: #f2f2f2; }
          @media (min-width: 768px) {
            [is='flynt-block-chronical'] .event-caption:not(.themeDark)::before {
              border-left-color: #f2f2f2;
              border-top-color: transparent; } }
        [is='flynt-block-chronical'] .event-caption:not(.themeDark)::after {
          background-color: #999;
          height: 14px;
          margin-right: -7px;
          margin-top: 4px;
          width: 14px; }
          @media (min-width: 768px) {
            [is='flynt-block-chronical'] .event-caption:not(.themeDark)::after {
              height: 24px;
              right: -39px;
              top: 45px;
              width: 24px; } }
          @media (min-width: 1280px) {
            [is='flynt-block-chronical'] .event-caption:not(.themeDark)::after {
              right: -73px; } }
      [is='flynt-block-chronical'] .event-caption::before, [is='flynt-block-chronical'] .event-caption::after {
        content: '';
        display: block;
        position: absolute; }
      [is='flynt-block-chronical'] .event-caption::before {
        border: 12px solid transparent;
        border-top-color: #333;
        height: 0;
        margin-right: -12px;
        right: 50%;
        top: 100%;
        width: 0;
        z-index: 2; }
        @media (min-width: 768px) {
          [is='flynt-block-chronical'] .event-caption::before {
            border-left-color: #333;
            border-top-color: transparent;
            border-width: 24px;
            margin-right: 0;
            right: -36px;
            top: 38px; } }
        @media (min-width: 1280px) {
          [is='flynt-block-chronical'] .event-caption::before {
            right: -48px; } }
      [is='flynt-block-chronical'] .event-caption::after {
        background-color: #666;
        border-radius: 50%;
        height: 24px;
        margin-right: -12px;
        right: 50%;
        top: 100%;
        width: 24px; }
        @media (min-width: 768px) {
          [is='flynt-block-chronical'] .event-caption::after {
            height: 28px;
            margin: 0;
            right: -48px;
            top: 49px;
            width: 28px; } }
        @media (min-width: 1280px) {
          [is='flynt-block-chronical'] .event-caption::after {
            right: -82px; } }
    [is='flynt-block-chronical'] .event--ready {
      opacity: 1; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption::before {
        border-left-color: transparent;
        border-right-color: #333;
        left: -36px;
        right: auto; } }
    @media (min-width: 1280px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption::before {
        left: -48px; } }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption::after {
        left: -48px;
        right: auto; } }
    @media (min-width: 1280px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption::after {
        left: -82px; } }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption:not(.themeDark)::before {
        border-right-color: #f2f2f2; } }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption:not(.themeDark)::after {
        left: -46px; } }
    @media (min-width: 1280px) {
      [is='flynt-block-chronical'] .event:nth-child(even) .event-caption:not(.themeDark)::after {
        left: -80px; } }
  [is='flynt-block-chronical'] .figure {
    background: #e6e6e6;
    height: 100%;
    margin-bottom: 0; }
    [is='flynt-block-chronical'] .figure-image--isHidden {
      visibility: hidden; }
  [is='flynt-block-chronical'] .video-playButton {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    outline: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
    [is='flynt-block-chronical'] .video-playButton::before {
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transition: transform 0.3s ease;
      background-color: rgba(10, 165, 175, 0.4);
      border: 2px solid #fff;
      border-radius: 50%;
      height: 60px;
      margin-left: -30px;
      margin-top: -30px;
      width: 60px; }
      @media (min-width: 768px) {
        [is='flynt-block-chronical'] .video-playButton::before {
          border-width: 4px;
          height: 76px;
          margin-left: -38px;
          margin-top: -38px;
          width: 76px; } }
    [is='flynt-block-chronical'] .video-playButton::after {
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transition: transform 0.3s ease;
      background: url("../assets/icons/play-triangle.svg") center center no-repeat;
      background-size: 100% 100%;
      height: 28px;
      margin-left: -7px;
      margin-top: -14px;
      width: 22px; }
      @media (min-width: 768px) {
        [is='flynt-block-chronical'] .video-playButton::after {
          height: 34px;
          margin-left: -9.6px;
          margin-top: -16px;
          width: 30px; } }
    [is='flynt-block-chronical'] .video-playButton:hover::before, [is='flynt-block-chronical'] .video-playButton:hover::after {
      transform: scale(1.1); }
  [is='flynt-block-chronical'] .video-loader {
    animation: loading 1.1s infinite linear;
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #fff;
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    display: none;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    text-indent: -9999em;
    top: 50%;
    z-index: 1; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .video-loader {
        margin-left: -51px;
        margin-top: -51px; } }
  [is='flynt-block-chronical'] .video-loader,
  [is='flynt-block-chronical'] .video-loader::after {
    border-radius: 50%;
    height: 60px;
    width: 60px; }
    @media (min-width: 768px) {
      [is='flynt-block-chronical'] .video-loader,
      [is='flynt-block-chronical'] .video-loader::after {
        height: 102px;
        width: 102px; } }

@keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  [is='flynt-block-chronical'] .video {
    position: relative;
    width: 100%; }
    @media (min-width: 1024px) {
      [is='flynt-block-chronical'] .video {
        flex-grow: 1;
        width: auto; } }
    [is='flynt-block-chronical'] .video-player {
      height: 0;
      padding-bottom: 56.25%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    [is='flynt-block-chronical'] .video iframe {
      display: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  [is='flynt-block-chronical'] .video-player--isLoading {
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.2s ease-out; }
    [is='flynt-block-chronical'] .video-player--isLoading .video-loader {
      display: block; }
    [is='flynt-block-chronical'] .video-player--isLoading .video-playButton {
      display: none; }
  [is='flynt-block-chronical'] .video-player--isLoaded .video-loader,
  [is='flynt-block-chronical'] .video-player--isLoaded .video-playButton {
    display: none; }
  [is='flynt-block-chronical'] .video-player--isLoaded iframe {
    display: block; }

[is='flynt-block-collapse'].flyntComponent {
  margin: -30px 0;
  padding: 5px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-collapse'].flyntComponent {
      margin: -68px 0;
      padding: 0; } }
  [is='flynt-block-collapse'].flyntComponent--dark {
    background: #333; }

[is='flynt-block-cookie-notice'] {
  bottom: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999; }
  [is='flynt-block-cookie-notice'] .container {
    padding-bottom: 34px;
    padding-right: 34px;
    padding-top: 34px; }
  [is='flynt-block-cookie-notice'] .content {
    font-size: 0.8rem;
    padding-bottom: 25px; }
    [is='flynt-block-cookie-notice'] .content > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-block-cookie-notice'] .content > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
  [is='flynt-block-cookie-notice'] .button, [is='flynt-block-cookie-notice'] .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-block-cookie-notice'] .gform_footer {
    white-space: nowrap; }
    [is='flynt-block-cookie-notice'] .button--accept:not(.button-hasIcon)::after {
      background-image: url("../assets/icons/icon-check-sprite.svg");
      background-size: 200% 100%;
      width: 18px; }
    [is='flynt-block-cookie-notice'] .button--deny {
      margin-bottom: 10px;
      margin-right: 20px; }
      @media (min-width: 768px) {
        [is='flynt-block-cookie-notice'] .button--deny {
          margin-bottom: 0; } }
      [is='flynt-block-cookie-notice'] .button--deny:not(.button-hasIcon)::after {
        background-image: url("../assets/icons/icon-close-sprite.svg");
        background-size: 200% 100%;
        width: 17px; }
  [is='flynt-block-cookie-notice'].cookieNotice--isVisible {
    display: block; }
  @media (min-width: 768px) {
    [is='flynt-block-cookie-notice'].layoutBottom .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-bottom: 42px;
      padding-top: 42px; }
    [is='flynt-block-cookie-notice'].layoutBottom .content {
      padding-bottom: 0;
      padding-right: 30px; } }
  @media (min-width: 768px) {
    [is='flynt-block-cookie-notice'].layoutFloating {
      bottom: 25px;
      left: 25px;
      max-width: 380px;
      width: calc(100% - (25px * 2)); }
      [is='flynt-block-cookie-notice'].layoutFloating .container {
        padding: 30px; } }

[is='flynt-block-facts'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-facts'] {
      margin: 68px 0; } }
  [is='flynt-block-facts'] .preContent > :first-child:not(.overlineText) {
    margin-top: 0;
    padding-top: 0; }
  [is='flynt-block-facts'] .preContent > :last-child:not(.overlineText) {
    margin-bottom: 0;
    padding-bottom: 0; }
  [is='flynt-block-facts'] .fact {
    display: flex;
    flex-direction: column; }
    @media (max-width: 767px) {
      [is='flynt-block-facts'] .fact {
        max-width: 500px; } }
    @media (min-width: 1024px) {
      [is='flynt-block-facts'] .fact {
        align-items: center;
        flex-direction: row; } }
    [is='flynt-block-facts'] .fact-left {
      hyphens: auto;
      margin-bottom: 20px;
      max-width: 700px;
      word-break: break-word; }
      @media (min-width: 768px) {
        [is='flynt-block-facts'] .fact-left {
          margin-bottom: 40px; } }
      @media (min-width: 1024px) {
        [is='flynt-block-facts'] .fact-left {
          margin-bottom: 0;
          padding-right: 50px;
          width: 44%; }
          [is='flynt-block-facts'] .fact-left h1,
          [is='flynt-block-facts'] .fact-left .h1,
          [is='flynt-block-facts'] .fact-left h2,
          [is='flynt-block-facts'] .fact-left .h2 {
            padding-right: 9px; }
          [is='flynt-block-facts'] .fact-left h3,
          [is='flynt-block-facts'] .fact-left .h3,
          [is='flynt-block-facts'] .fact-left h4,
          [is='flynt-block-facts'] .fact-left .h4,
          [is='flynt-block-facts'] .fact-left h5,
          [is='flynt-block-facts'] .fact-left .h5,
          [is='flynt-block-facts'] .fact-left h6,
          [is='flynt-block-facts'] .fact-left .h6 {
            padding-right: 7px; } }
    [is='flynt-block-facts'] .fact-right {
      align-items: center;
      display: flex;
      hyphens: auto;
      max-width: 800px;
      word-break: break-word; }
      @media (min-width: 1024px) {
        [is='flynt-block-facts'] .fact-right {
          justify-content: center;
          width: 56%; } }
    [is='flynt-block-facts'] .fact-icon {
      margin-right: 25px;
      min-width: 70px;
      width: 70px; }
      @media (min-width: 768px) {
        [is='flynt-block-facts'] .fact-icon {
          margin-right: 60px;
          min-width: 150px;
          width: 150px; } }
      [is='flynt-block-facts'] .fact-icon svg,
      [is='flynt-block-facts'] .fact-icon img {
        display: block;
        height: auto;
        margin: 0 0 0 auto;
        max-width: 125px;
        width: 100%; }
    [is='flynt-block-facts'] .fact-item {
      border-left: 1px solid #666;
      padding: 0 0 0 25px; }
      @media (min-width: 768px) {
        [is='flynt-block-facts'] .fact-item {
          min-width: 66%;
          padding: 20px 0 20px 60px; } }
    [is='flynt-block-facts'] .fact-number {
      color: #003769;
      font-size: 3.25rem;
      padding: 0; }
      @media (max-width: 479px) {
        [is='flynt-block-facts'] .fact-number--small {
          font-size: 2.5rem; } }
      @media (min-width: 1280px) {
        [is='flynt-block-facts'] .fact-number {
          font-size: 6.2rem; }
          [is='flynt-block-facts'] .fact-number--small {
            font-size: 4.5rem; } }
    [is='flynt-block-facts'] .fact-subtitle {
      font-size: 0.875rem; }
      @media (min-width: 768px) {
        [is='flynt-block-facts'] .fact-subtitle {
          font-size: 1rem; } }
      @media (min-width: 1280px) {
        [is='flynt-block-facts'] .fact-subtitle {
          font-size: 1.31rem; } }
  @media (min-width: 1024px) {
    [is='flynt-block-facts'].flyntComponent--left .fact {
      flex-direction: row-reverse; }
      [is='flynt-block-facts'].flyntComponent--left .fact-left {
        padding-left: 50px;
        padding-right: 0;
        text-align: right; }
      [is='flynt-block-facts'].flyntComponent--left .fact-right {
        flex-direction: row-reverse; }
      [is='flynt-block-facts'].flyntComponent--left .fact-icon {
        margin-left: 45px;
        margin-right: 0; }
      [is='flynt-block-facts'].flyntComponent--left .fact-item {
        border-left: 0;
        border-right: 1px solid #666;
        padding: 20px 60px 20px 0; } }

[is='flynt-block-image'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-image'] {
      margin: 68px 0; } }
  [is='flynt-block-image'] .figure {
    background: transparent; }
    [is='flynt-block-image'] .figure-revealer {
      background-color: #f2f2f2; }
    [is='flynt-block-image'] .figure-caption {
      line-height: 1.4;
      margin: 0;
      padding: 15px 15px 0; }
      @media (min-width: 768px) {
        [is='flynt-block-image'] .figure-caption {
          line-height: 1.85;
          padding: 27px 34px 0; } }
  @media (min-width: 768px) {
    [is='flynt-block-image'] .figure-ratio-3-1 img {
      object-fit: cover;
      object-position: center 17%;
      max-height: 233px; } }
  @media (min-width: 1024px) {
    [is='flynt-block-image'] .figure-ratio-3-1 img {
      max-height: 319px; } }
  @media (min-width: 1280px) {
    [is='flynt-block-image'] .figure-ratio-3-1 img {
      max-height: 408px; } }

[is='flynt-block-list-jobs'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-list-jobs'] {
      margin: 68px 0; } }
  [is='flynt-block-list-jobs'] .centerMaxWidthContainer {
    max-width: 1156px; }
  [is='flynt-block-list-jobs'] .slider {
    height: 100%;
    position: relative; }
  [is='flynt-block-list-jobs'] .bottom-link {
    margin-left: 25px; }
    @media (min-width: 768px) {
      [is='flynt-block-list-jobs'] .bottom-link {
        margin-left: 34px; } }
    @media (min-width: 1280px) {
      [is='flynt-block-list-jobs'] .bottom-link {
        margin-left: 0; } }
  [is='flynt-block-list-jobs'] .fallback-message > :first-child:not(.overlineText) {
    margin-top: 0;
    padding-top: 0; }
  [is='flynt-block-list-jobs'] .fallback-message > :last-child:not(.overlineText) {
    margin-bottom: 0;
    padding-bottom: 0; }
  [is='flynt-block-list-jobs'] .slider-caption {
    margin-bottom: 40px;
    position: relative; }
    @media (max-width: 767px) {
      [is='flynt-block-list-jobs'] .slider-caption {
        padding-left: 25px;
        padding-right: 25px; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      [is='flynt-block-list-jobs'] .slider-caption {
        padding-left: 34px;
        padding-right: 34px; } }
    [is='flynt-block-list-jobs'] .slider-caption .caption {
      padding-right: 0;
      background-color: #666;
      color: #fff;
      display: block;
      font-weight: normal;
      height: 100%;
      outline: none; }
      [is='flynt-block-list-jobs'] .slider-caption .caption::after, [is='flynt-block-list-jobs'] .slider-caption .caption::before {
        content: none; }
      @media (min-width: 576px) and (max-width: 1023px) {
        [is='flynt-block-list-jobs'] .slider-caption .caption {
          min-height: 41vw; } }
      @media (min-width: 1024px) and (max-width: 1279px) {
        [is='flynt-block-list-jobs'] .slider-caption .caption {
          min-height: 26vw; } }
      @media (min-width: 1280px) {
        [is='flynt-block-list-jobs'] .slider-caption .caption {
          min-height: 272px; } }
      [is='flynt-block-list-jobs'] .slider-caption .caption:hover .button-wrap,
      [is='flynt-block-list-jobs'] .slider-caption .caption:hover .button,
      [is='flynt-block-list-jobs'] .slider-caption .caption:hover .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-block-list-jobs'] .slider-caption .caption:hover .gform_footer, [is='flynt-block-list-jobs'] .slider-caption .caption:focus .button-wrap,
      [is='flynt-block-list-jobs'] .slider-caption .caption:focus .button,
      [is='flynt-block-list-jobs'] .slider-caption .caption:focus .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-block-list-jobs'] .slider-caption .caption:focus .gform_footer {
        background-color: #0aa5af; }
      [is='flynt-block-list-jobs'] .slider-caption .caption-content {
        padding: 25px 25px 50px; }
        [is='flynt-block-list-jobs'] .slider-caption .caption-content > :first-child:not(.overlineText) {
          margin-top: 0;
          padding-top: 0; }
        [is='flynt-block-list-jobs'] .slider-caption .caption-content > :last-child:not(.overlineText) {
          margin-bottom: 0;
          padding-bottom: 0; }
        @media (min-width: 768px) {
          [is='flynt-block-list-jobs'] .slider-caption .caption-content {
            padding: 30px 34px 55px; } }
        [is='flynt-block-list-jobs'] .slider-caption .caption-content p {
          margin-bottom: 20px; }
    [is='flynt-block-list-jobs'] .slider-caption .slider-item {
      height: auto;
      position: relative; }
      [is='flynt-block-list-jobs'] .slider-caption .slider-item:nth-child(even) .caption {
        background-color: #999; }
      @media (min-width: 576px) {
        [is='flynt-block-list-jobs'] .slider-caption .slider-item:nth-child(even) {
          margin-top: 30px; } }
      @media (min-width: 768px) {
        [is='flynt-block-list-jobs'] .slider-caption .slider-item:nth-child(even) {
          margin-top: 68px; } }
      @media (min-width: 576px) {
        [is='flynt-block-list-jobs'] .slider-caption .slider-item:nth-child(odd) {
          margin-bottom: 30px; } }
      @media (min-width: 768px) {
        [is='flynt-block-list-jobs'] .slider-caption .slider-item:nth-child(odd) {
          margin-bottom: 68px; } }
    [is='flynt-block-list-jobs'] .slider-caption .button-wrap {
      background: #003769;
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute; }
  [is='flynt-block-list-jobs'] .swiper-button-lock {
    display: none; }
  [is='flynt-block-list-jobs'] .slider-button {
    height: 26px;
    margin-top: -13px;
    outline: none;
    position: absolute;
    top: 50%;
    width: 25px;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-block-list-jobs'] .slider-button {
        height: 34px;
        margin-top: -17px;
        width: 34px; } }
    [is='flynt-block-list-jobs'] .slider-button--prev {
      left: -25px; }
      @media (min-width: 768px) {
        [is='flynt-block-list-jobs'] .slider-button--prev {
          left: -34px; } }
    [is='flynt-block-list-jobs'] .slider-button--next {
      right: -25px; }
      @media (min-width: 768px) {
        [is='flynt-block-list-jobs'] .slider-button--next {
          right: -34px; } }
  [is='flynt-block-list-jobs'].themeDark {
    padding: 30px 0; }
    @media (min-width: 768px) {
      [is='flynt-block-list-jobs'].themeDark {
        padding: 68px 0; } }
  [is='flynt-block-list-jobs'] .parallax {
    transition: transform 0.1s;
    will-change: transform; }
    @media (min-width: 576px) {
      [is='flynt-block-list-jobs'] .parallax-elem-1, [is='flynt-block-list-jobs'] .parallax-elem-3 {
        transform: translateY(var(--slideUp)); } }
    @media (min-width: 576px) {
      [is='flynt-block-list-jobs'] .parallax-elem-2, [is='flynt-block-list-jobs'] .parallax-elem-4 {
        transform: translateY(var(--slideDown)); } }

[is='flynt-block-not-found'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-not-found'] {
      margin: 68px 0; } }
  [is='flynt-block-not-found'] .content {
    margin: 0 auto;
    max-width: 1020px;
    text-align: left; }
    [is='flynt-block-not-found'] .content > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-block-not-found'] .content > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
  [is='flynt-block-not-found'] .image {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      [is='flynt-block-not-found'] .image {
        margin-bottom: 55px; } }

[is='flynt-block-social-share'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-social-share'] {
      margin: 68px 0; } }
  [is='flynt-block-social-share'] .social {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin: -1px 0 0;
    padding: 0; }
    [is='flynt-block-social-share'] .social > li::before {
      content: none; }
    [is='flynt-block-social-share'] .social-item {
      margin: 0; }
      [is='flynt-block-social-share'] .social-item:nth-child(even) .social-link {
        background-color: #333; }
      [is='flynt-block-social-share'] .social-item .social-link {
        padding-right: 0;
        align-items: center;
        background-color: #666;
        display: block;
        display: flex;
        height: 34px;
        justify-content: center;
        transition: color 0.2s ease;
        transition: background-color 0.2s ease;
        width: 34px; }
        [is='flynt-block-social-share'] .social-item .social-link::after, [is='flynt-block-social-share'] .social-item .social-link::before {
          content: none; }
        [is='flynt-block-social-share'] .social-item .social-link:focus {
          outline: none; }
        [is='flynt-block-social-share'] .social-item .social-link--facebook:hover, [is='flynt-block-social-share'] .social-item .social-link--facebook:focus {
          background-color: #1877f2; }
        [is='flynt-block-social-share'] .social-item .social-link--twitter:hover, [is='flynt-block-social-share'] .social-item .social-link--twitter:focus {
          background-color: #00acee; }
        [is='flynt-block-social-share'] .social-item .social-link--linkedin:hover, [is='flynt-block-social-share'] .social-item .social-link--linkedin:focus {
          background-color: #0076b2; }
        [is='flynt-block-social-share'] .social-item .social-link--whatsapp:hover, [is='flynt-block-social-share'] .social-item .social-link--whatsapp:focus {
          background-color: #26d466; }
        [is='flynt-block-social-share'] .social-item .social-link--xing:hover, [is='flynt-block-social-share'] .social-item .social-link--xing:focus {
          background-color: #00595e; }
        [is='flynt-block-social-share'] .social-item .social-link svg {
          display: block; }
    [is='flynt-block-social-share'] .social-wrap {
      align-items: center;
      border-top: 1px solid #666;
      display: flex;
      justify-content: flex-end; }
    [is='flynt-block-social-share'] .social-label {
      color: #666;
      padding-right: 24px; }

[is='flynt-block-table'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-table'] {
      margin: 68px 0; } }
  [is='flynt-block-table'] .preContent {
    margin-bottom: 1.5rem;
    max-width: 1020px; }
    [is='flynt-block-table'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-block-table'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-block-table'] .preContent {
        margin-bottom: 3.4rem; } }
  [is='flynt-block-table'] .table-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 1292px) {
      [is='flynt-block-table'] .table-container {
        padding-left: 34px;
        padding-right: 34px; } }
  [is='flynt-block-table'] .swiper-container-horizontal {
    width: 100%; }
    [is='flynt-block-table'] .swiper-container-horizontal > .swiper-scrollbar {
      background-color: #f2f2f2;
      border-radius: 8px;
      height: 15px;
      left: 25px;
      width: calc(100% - 50px); }
    @media (max-width: 1291px) {
      [is='flynt-block-table'] .swiper-container-horizontal::before, [is='flynt-block-table'] .swiper-container-horizontal::after {
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2; }
      [is='flynt-block-table'] .swiper-container-horizontal::before {
        background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
        left: 0;
        width: 25px; }
      [is='flynt-block-table'] .swiper-container-horizontal::after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
        right: 0;
        width: 25px; } }
  [is='flynt-block-table'] table {
    margin: 0 0 2.5rem; }
  [is='flynt-block-table'] .swiper-scrollbar-drag {
    background-color: #999;
    height: 9px;
    margin-left: 3px;
    margin-top: 3px; }
  [is='flynt-block-table'] .swiper-slide {
    margin-left: 25px;
    min-width: 100%;
    width: 1224px; }
    @media (min-width: 1292px) {
      [is='flynt-block-table'] .swiper-slide {
        margin-left: 0; } }

[is='flynt-block-video-oembed'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-video-oembed'] {
      margin: 68px 0; } }
  .mainContent [is='flynt-block-video-oembed'].flyntComponent:first-child {
    margin-top: 25px; }
    @media (min-width: 768px) {
      .mainContent [is='flynt-block-video-oembed'].flyntComponent:first-child {
        margin-top: 34px; } }
  [is='flynt-block-video-oembed'] .videoLayout {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: -25px;
    margin-right: -25px; }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .videoLayout {
        margin-left: -34px;
        margin-right: -34px; } }
    @media (min-width: 1024px) {
      [is='flynt-block-video-oembed'] .videoLayout {
        flex-direction: row;
        margin-left: 0;
        margin-right: 0; }
        [is='flynt-block-video-oembed'] .videoLayout--left {
          flex-direction: row-reverse; } }
  [is='flynt-block-video-oembed'].flyntComponent--overlappingBackground {
    padding: 0; }
    [is='flynt-block-video-oembed'].flyntComponent--overlappingBackground::after {
      background-color: #333;
      content: '';
      display: block;
      height: 96px;
      margin-top: -66px;
      width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-block-video-oembed'].flyntComponent--overlappingBackground::after {
          height: 150px;
          margin-top: -116px; } }
  [is='flynt-block-video-oembed'] .figure {
    margin-bottom: 0;
    overflow: hidden; }
    [is='flynt-block-video-oembed'] .figure-image {
      background: rgba(0, 0, 0, 0.03); }
      [is='flynt-block-video-oembed'] .figure-image--isHidden {
        visibility: hidden; }
  [is='flynt-block-video-oembed'] .video-playButton {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    outline: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
    [is='flynt-block-video-oembed'] .video-playButton::before {
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transition: transform 0.3s ease;
      background-color: rgba(10, 165, 175, 0.4);
      border: 2px solid #fff;
      border-radius: 50%;
      height: 60px;
      margin-left: -30px;
      margin-top: -30px;
      width: 60px; }
      @media (min-width: 768px) {
        [is='flynt-block-video-oembed'] .video-playButton::before {
          border-width: 4px;
          height: 102px;
          margin-left: -51px;
          margin-top: -51px;
          width: 102px; } }
    [is='flynt-block-video-oembed'] .video-playButton::after {
      content: '';
      left: 50%;
      position: absolute;
      top: 50%;
      transition: transform 0.3s ease;
      background: url("../assets/icons/play-triangle.svg") center center no-repeat;
      background-size: 100% 100%;
      height: 28px;
      margin-left: -8.4px;
      margin-top: -14px;
      width: 22px; }
      @media (min-width: 768px) {
        [is='flynt-block-video-oembed'] .video-playButton::after {
          height: 50px;
          margin-left: -15px;
          margin-top: -25px;
          width: 40px; } }
    [is='flynt-block-video-oembed'] .video-playButton:hover::before, [is='flynt-block-video-oembed'] .video-playButton:hover::after {
      transform: scale(1.1); }
  [is='flynt-block-video-oembed'] .video-loader {
    animation: loading 1.1s infinite linear;
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #fff;
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    display: none;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    position: absolute;
    text-indent: -9999em;
    top: 50%;
    z-index: 1; }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .video-loader {
        margin-left: -51px;
        margin-top: -51px; } }
  [is='flynt-block-video-oembed'] .video-loader,
  [is='flynt-block-video-oembed'] .video-loader::after {
    border-radius: 50%;
    height: 60px;
    width: 60px; }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .video-loader,
      [is='flynt-block-video-oembed'] .video-loader::after {
        height: 102px;
        width: 102px; } }

@keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  [is='flynt-block-video-oembed'] .video {
    position: relative;
    width: 100%; }
    @media (min-width: 1024px) {
      [is='flynt-block-video-oembed'] .video {
        flex-grow: 1;
        width: auto; } }
    [is='flynt-block-video-oembed'] .video-player {
      height: 0;
      padding-bottom: 56.25%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2; }
    [is='flynt-block-video-oembed'] .video iframe {
      display: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  [is='flynt-block-video-oembed'] .video-player--isLoading {
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.2s ease-out; }
    [is='flynt-block-video-oembed'] .video-player--isLoading .video-loader {
      display: block; }
    [is='flynt-block-video-oembed'] .video-player--isLoading .video-playButton {
      display: none; }
  [is='flynt-block-video-oembed'] .video-player--isLoaded .video-loader,
  [is='flynt-block-video-oembed'] .video-player--isLoaded .video-playButton {
    display: none; }
  [is='flynt-block-video-oembed'] .video-player--isLoaded iframe {
    display: block; }
  [is='flynt-block-video-oembed'] .video-caption {
    background-color: #000;
    padding: 18px 25px;
    width: 100%; }
    @media (max-width: 1023px) {
      [is='flynt-block-video-oembed'] .video-caption {
        transform: none !important; } }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .video-caption {
        padding: 31px 34px; } }
    @media (min-width: 1024px) {
      [is='flynt-block-video-oembed'] .video-caption {
        min-width: 238px;
        width: 238px; } }

[is='flynt-block-wysiwyg'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-block-wysiwyg'] {
      margin: 68px 0; } }
  [is='flynt-block-wysiwyg'][class*='theme'] {
    margin: 0;
    padding: 30px 0; }
    @media (min-width: 1280px) {
      [is='flynt-block-wysiwyg'][class*='theme'] {
        padding: 68px 0; } }
  .mainContent [is='flynt-block-wysiwyg'].flyntComponent:not(.themeDark) {
    margin-top: 30px; }
    @media (min-width: 768px) {
      .mainContent [is='flynt-block-wysiwyg'].flyntComponent:not(.themeDark) {
        margin-top: 68px; } }
  .mainContent [is='flynt-block-wysiwyg'].flyntComponent[class*='theme'] {
    margin: 0;
    padding-top: 30px; }
    @media (min-width: 1280px) {
      .mainContent [is='flynt-block-wysiwyg'].flyntComponent[class*='theme'] {
        padding-top: 68px; } }
  @media (min-width: 768px) {
    [is='flynt-block-wysiwyg'] .contentSize-medium {
      max-width: calc(100% * 4/5); }
    [is='flynt-block-wysiwyg'] .contentSize-small {
      max-width: calc(100% * 3/5); }
    [is='flynt-block-wysiwyg'] .contentSize-tiny {
      max-width: calc(100% * 2/5); }
    [is='flynt-block-wysiwyg'] .contentSize-withImage {
      max-width: 816px; } }
  [is='flynt-block-wysiwyg'] .container--doubleColumn {
    display: flex;
    justify-content: flex-end; }
    [is='flynt-block-wysiwyg'] .container--doubleColumn .container-wrapper {
      display: flex;
      flex-direction: column-reverse; }
      @media (min-width: 768px) {
        [is='flynt-block-wysiwyg'] .container--doubleColumn .container-wrapper {
          flex-direction: row;
          justify-content: space-between;
          margin: 0;
          max-width: 1120px;
          width: 100%; } }
  [is='flynt-block-wysiwyg'] .content {
    text-align: inherit; }
    [is='flynt-block-wysiwyg'] .content > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-block-wysiwyg'] .content > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    [is='flynt-block-wysiwyg'] .content--textLeft {
      text-align: left; }
    [is='flynt-block-wysiwyg'] .content--textCenter {
      text-align: center; }
    [is='flynt-block-wysiwyg'] .content-image {
      margin-bottom: 20px;
      width: 100px; }
      @media (min-width: 768px) {
        [is='flynt-block-wysiwyg'] .content-image {
          margin-bottom: 0;
          margin-left: 20px;
          width: 200px; } }

[is='flynt-cookiebot-declaration'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-cookiebot-declaration'] {
      margin: 68px 0; }
      [is='flynt-cookiebot-declaration'] .contentSize-medium {
        max-width: calc(100% * 4/5); }
      [is='flynt-cookiebot-declaration'] .contentSize-small {
        max-width: calc(100% * 3/5); }
      [is='flynt-cookiebot-declaration'] .contentSize-tiny {
        max-width: calc(100% * 2/5); } }

[is='flynt-form-contact'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-form-contact'] {
      margin: 68px 0; } }

[is='flynt-grid-image-text'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-grid-image-text'] {
      margin: 68px 0; } }
  [is='flynt-grid-image-text'] .preContent {
    margin-bottom: 1.5rem; }
    [is='flynt-grid-image-text'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-grid-image-text'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-grid-image-text'] .preContent {
        margin-bottom: 3.4rem; } }
  [is='flynt-grid-image-text'] .grid {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: -22px 0 0 -22px; }
    [is='flynt-grid-image-text'] .grid > li::before {
      content: none; }
    @media (min-width: 576px) {
      [is='flynt-grid-image-text'] .grid {
        margin: -25px 0 0 -25px; } }
    @media (min-width: 768px) {
      [is='flynt-grid-image-text'] .grid {
        margin: -34px 0 0 -34px; } }
    @media (min-width: 1024px) {
      [is='flynt-grid-image-text'] .grid {
        margin: -48px 0 0 -48px;
        flex-wrap: nowrap; }
        [is='flynt-grid-image-text'] .grid .caption-title {
          font-size: 1.125rem; } }
    @media (min-width: 1280px) {
      [is='flynt-grid-image-text'] .grid {
        margin: -68px 0 0 -68px; } }
    [is='flynt-grid-image-text'] .grid .grid-item {
      padding: 22px 0 0 22px;
      width: 100%; }
      @media (min-width: 576px) {
        [is='flynt-grid-image-text'] .grid .grid-item {
          padding: 25px 0 0 25px; } }
      @media (min-width: 768px) {
        [is='flynt-grid-image-text'] .grid .grid-item {
          padding: 34px 0 0 34px;
          width: 50%; } }
      @media (min-width: 1024px) {
        [is='flynt-grid-image-text'] .grid .grid-item {
          padding: 48px 0 0 48px;
          width: 33.3333%; } }
      @media (min-width: 1280px) {
        [is='flynt-grid-image-text'] .grid .grid-item {
          padding: 68px 0 0 68px; } }
  [is='flynt-grid-image-text'] .content {
    height: 100%; }
    [is='flynt-grid-image-text'] .content .link-content {
      padding-right: 0;
      display: block; }
      [is='flynt-grid-image-text'] .content .link-content::after, [is='flynt-grid-image-text'] .content .link-content::before {
        content: none; }
      [is='flynt-grid-image-text'] .content .link-content:hover .button-wrap,
      [is='flynt-grid-image-text'] .content .link-content:hover .button,
      [is='flynt-grid-image-text'] .content .link-content:hover .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-grid-image-text'] .content .link-content:hover .gform_footer, [is='flynt-grid-image-text'] .content .link-content:focus .button-wrap,
      [is='flynt-grid-image-text'] .content .link-content:focus .button,
      [is='flynt-grid-image-text'] .content .link-content:focus .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-grid-image-text'] .content .link-content:focus .gform_footer {
        background: #0aa5af; }
      [is='flynt-grid-image-text'] .content .link-content:hover .content-image, [is='flynt-grid-image-text'] .content .link-content:focus .content-image {
        transform: scale(1); }
    [is='flynt-grid-image-text'] .content-image {
      transform: scale(1.3);
      transform-origin: 50% 50%;
      transition: transform 0.5s ease, opacity 0.5s ease; }
    [is='flynt-grid-image-text'] .content-figure {
      background: #333;
      margin: 0;
      overflow: hidden; }
    [is='flynt-grid-image-text'] .content-inner {
      padding-right: 30px;
      position: relative;
      z-index: 2; }
      @media (min-width: 768px) {
        [is='flynt-grid-image-text'] .content-inner {
          padding-right: 34px; } }
      [is='flynt-grid-image-text'] .content-inner .caption {
        background: #fff;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
        padding: 20px 25px; }
        [is='flynt-grid-image-text'] .content-inner .caption > :first-child:not(.overlineText) {
          margin-top: 0;
          padding-top: 0; }
        [is='flynt-grid-image-text'] .content-inner .caption > :last-child:not(.overlineText) {
          margin-bottom: 0;
          padding-bottom: 0; }
        @media (min-width: 768px) {
          [is='flynt-grid-image-text'] .content-inner .caption {
            padding: 20px 34px; } }
        [is='flynt-grid-image-text'] .content-inner .caption-title {
          line-height: 1.2; }
      [is='flynt-grid-image-text'] .content-inner .button-wrap {
        background: #003769;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0; }

[is='flynt-grid-image-text-vertical'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-grid-image-text-vertical'] {
      margin: 68px 0; } }
  [is='flynt-grid-image-text-vertical'] .preContent {
    margin-bottom: 1.5rem; }
    [is='flynt-grid-image-text-vertical'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-grid-image-text-vertical'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-grid-image-text-vertical'] .preContent {
        margin-bottom: 3.4rem; } }
  [is='flynt-grid-image-text-vertical'] .grid {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -22px 0 0 -22px; }
    [is='flynt-grid-image-text-vertical'] .grid > li::before {
      content: none; }
    @media (min-width: 576px) {
      [is='flynt-grid-image-text-vertical'] .grid {
        margin: -25px 0 0 -25px; } }
    @media (min-width: 768px) {
      [is='flynt-grid-image-text-vertical'] .grid {
        margin: -34px 0 0 -34px; } }
    @media (min-width: 1024px) {
      [is='flynt-grid-image-text-vertical'] .grid {
        margin: -48px 0 0 -48px; } }
    @media (min-width: 576px) {
      [is='flynt-grid-image-text-vertical'] .grid--length2 .grid-item, [is='flynt-grid-image-text-vertical'] .grid--length3 .grid-item {
        padding: 25px 0 0 25px;
        width: 50%; } }
    @media (min-width: 768px) {
      [is='flynt-grid-image-text-vertical'] .grid--length2 .grid-item, [is='flynt-grid-image-text-vertical'] .grid--length3 .grid-item {
        padding: 34px 0 0 34px; } }
    @media (min-width: 1024px) {
      [is='flynt-grid-image-text-vertical'] .grid--length2 .grid-item, [is='flynt-grid-image-text-vertical'] .grid--length3 .grid-item {
        padding: 48px 0 0 48px; } }
    @media (min-width: 1280px) {
      [is='flynt-grid-image-text-vertical'] .grid--length2 {
        margin: -68px 0 0 -68px; }
        [is='flynt-grid-image-text-vertical'] .grid--length2 .grid-item {
          padding: 68px 0 0 68px; } }
    @media (min-width: 1024px) {
      [is='flynt-grid-image-text-vertical'] .grid--length3 {
        flex-wrap: nowrap; }
        [is='flynt-grid-image-text-vertical'] .grid--length3 .item-title {
          font-size: 1.125rem; }
        [is='flynt-grid-image-text-vertical'] .grid--length3 .grid-item {
          width: 33.3333%; } }
    @media (min-width: 1280px) {
      [is='flynt-grid-image-text-vertical'] .grid--length3 {
        margin: -68px 0 0 -68px; }
        [is='flynt-grid-image-text-vertical'] .grid--length3 .grid-item {
          padding: 68px 0 0 68px; } }
  [is='flynt-grid-image-text-vertical'] .grid-item {
    padding: 22px 0 0 22px;
    width: 100%; }
  [is='flynt-grid-image-text-vertical'] .content {
    height: 100%; }
    [is='flynt-grid-image-text-vertical'] .content .link-content {
      padding-right: 0;
      display: block; }
      [is='flynt-grid-image-text-vertical'] .content .link-content::after, [is='flynt-grid-image-text-vertical'] .content .link-content::before {
        content: none; }
      [is='flynt-grid-image-text-vertical'] .content .link-content:hover .button-wrap,
      [is='flynt-grid-image-text-vertical'] .content .link-content:hover .button,
      [is='flynt-grid-image-text-vertical'] .content .link-content:hover .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-grid-image-text-vertical'] .content .link-content:hover .gform_footer, [is='flynt-grid-image-text-vertical'] .content .link-content:focus .button-wrap,
      [is='flynt-grid-image-text-vertical'] .content .link-content:focus .button,
      [is='flynt-grid-image-text-vertical'] .content .link-content:focus .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-grid-image-text-vertical'] .content .link-content:focus .gform_footer {
        background: #0aa5af; }
      [is='flynt-grid-image-text-vertical'] .content .link-content:hover .content-image, [is='flynt-grid-image-text-vertical'] .content .link-content:focus .content-image {
        transform: scale(1); }
    [is='flynt-grid-image-text-vertical'] .content-image {
      transform: scale(1.3);
      transform-origin: 50% 50%;
      transition: transform 0.5s ease, opacity 0.5s ease; }
      [is='flynt-grid-image-text-vertical'] .content-image:hover {
        transform: scale(1); }
    [is='flynt-grid-image-text-vertical'] .content-figure {
      background: #333;
      margin: 0;
      overflow: hidden; }
    [is='flynt-grid-image-text-vertical'] .content-inner {
      position: relative;
      z-index: 2;
      background: #fff;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15); }
      [is='flynt-grid-image-text-vertical'] .content-inner .item-title {
        margin-bottom: 0;
        line-height: 1.2;
        padding: 20px 25px; }
        [is='flynt-grid-image-text-vertical'] .content-inner .item-title > :first-child:not(.overlineText) {
          margin-top: 0;
          padding-top: 0; }
        [is='flynt-grid-image-text-vertical'] .content-inner .item-title > :last-child:not(.overlineText) {
          margin-bottom: 0;
          padding-bottom: 0; }
        @media (min-width: 768px) {
          [is='flynt-grid-image-text-vertical'] .content-inner .item-title {
            padding: 20px 50px 20px 34px; } }
      [is='flynt-grid-image-text-vertical'] .content-inner .button-wrap {
        background: #003769;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0; }
      [is='flynt-grid-image-text-vertical'] .content-inner .content-trigger {
        cursor: pointer;
        margin: 0;
        outline: 0;
        position: absolute;
        top: 15px;
        right: 20px;
        width: 100%;
        padding: 13px 30px 13px 0; }
        @media (min-width: 768px) {
          [is='flynt-grid-image-text-vertical'] .content-inner .content-trigger {
            padding: 17px 30px 17px 0; } }
        [is='flynt-grid-image-text-vertical'] .content-inner .content-trigger::after {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='20' height='12' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.592 1.577l8.485 8.485 8.485-8.485' stroke='rgb(10, 165, 175)' stroke-width='2' fill='none' fill-rule='evenodd'/%3e%3c/svg%3e");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
          width: 18px; }
          @media (min-width: 768px) {
            [is='flynt-grid-image-text-vertical'] .content-inner .content-trigger::after {
              background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='28' height='16'%3e%3cpath d='M1.272 1L14 13.728 26.728 1' stroke='rgb(10, 165, 175)' stroke-width='3' fill='none' fill-rule='evenodd'/%3e%3c/svg%3e");
              width: 28px; } }
        [is='flynt-grid-image-text-vertical'] .content-inner .content-trigger[aria-expanded='true']::after {
          transform: rotateX(180deg); }
      [is='flynt-grid-image-text-vertical'] .content-inner .item-details {
        margin-top: 0;
        background: #0aa5af;
        padding: 20px 25px;
        display: none; }
        @media (min-width: 768px) {
          [is='flynt-grid-image-text-vertical'] .content-inner .item-details {
            padding: 20px 34px; } }
      [is='flynt-grid-image-text-vertical'] .content-inner .item-detail {
        color: #ffffff;
        padding-left: 15px;
        margin-bottom: 0.5rem; }
        [is='flynt-grid-image-text-vertical'] .content-inner .item-detail:last-child {
          margin-bottom: 0; }
        [is='flynt-grid-image-text-vertical'] .content-inner .item-detail:before {
          background: #ffffff;
          left: 0; }

[is='flynt-hero-header'] {
  margin: 30px 0;
  padding: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-hero-header'] {
      margin: 68px 0;
      padding: 0; } }
  [is='flynt-hero-header'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1156px;
    padding-left: 25px;
    padding-right: 25px; }
    @media (min-width: 768px) {
      [is='flynt-hero-header'] .container {
        padding-left: 34px;
        padding-right: 34px; } }
    @media (min-width: 1280px) {
      [is='flynt-hero-header'] .container {
        padding-left: 68px;
        padding-right: 34px; } }
  [is='flynt-hero-header'] .hero {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 450px;
    position: relative; }
    @media (min-width: 768px) {
      [is='flynt-hero-header'] .hero {
        flex-direction: row;
        max-width: none; } }
    @media (min-width: 768px) {
      [is='flynt-hero-header'] .hero--textRight {
        flex-direction: row-reverse; } }
  [is='flynt-hero-header'] .parallax {
    will-change: transform; }
    [is='flynt-hero-header'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
    @media (min-width: 768px) {
      [is='flynt-hero-header'] .parallax-elem-2 {
        transform: translateY(var(--slideUp)); } }
  @media (max-width: 767px) {
    [is='flynt-hero-header'] .column--image {
      margin-bottom: -50%;
      margin-left: -25px;
      width: 100%; } }
  @media (min-width: 768px) {
    [is='flynt-hero-header'] .column--image {
      width: 64.6%; } }
  @media (min-width: 1024px) {
    [is='flynt-hero-header'] .column--image {
      width: 680px; } }
  [is='flynt-hero-header'] .column--image .figure {
    margin: 0;
    overflow: hidden; }
    [is='flynt-hero-header'] .column--image .figure-image {
      transform: scale(1.2); }
  @media (min-width: 768px) {
    [is='flynt-hero-header'] .column--content {
      margin-left: -15%;
      padding-top: 10%;
      width: 75%; } }
  @media (min-width: 1024px) {
    [is='flynt-hero-header'] .column--content {
      margin-left: 0;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 68px;
      width: 510px; } }
  @media (min-width: 768px) {
    [is='flynt-hero-header'] .hero--textRight .column--content {
      margin-left: 0;
      margin-right: -13%; } }
  [is='flynt-hero-header'] .caption {
    background: #fff;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
    padding: 25px 25px 50px;
    position: relative;
    z-index: 2; }
    @media (min-width: 480px) {
      [is='flynt-hero-header'] .caption {
        min-height: 450px; } }
    @media (min-width: 768px) {
      [is='flynt-hero-header'] .caption {
        min-height: 480px;
        padding: 34px 34px 68px; } }
    @media (min-width: 1024px) {
      [is='flynt-hero-header'] .caption {
        min-height: 510px; } }
    @media (min-width: 1280px) {
      [is='flynt-hero-header'] .caption {
        padding: 34px 34px 102px; } }
    [is='flynt-hero-header'] .caption-content > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-hero-header'] .caption-content > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    [is='flynt-hero-header'] .caption-content + .button, [is='flynt-hero-header'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-header'] .caption-content + .gform_footer {
      margin-top: 20px;
      padding-right: 30px; }
      @media (min-width: 768px) {
        [is='flynt-hero-header'] .caption-content + .button, [is='flynt-hero-header'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-header'] .caption-content + .gform_footer {
          margin-top: 40px;
          padding-right: 35px; } }
      @media (min-width: 1280px) {
        [is='flynt-hero-header'] .caption-content + .button, [is='flynt-hero-header'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-header'] .caption-content + .gform_footer {
          margin-top: 60px; } }
    [is='flynt-hero-header'] .caption .overlineText {
      margin-bottom: 20px; }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-hero-header'] .caption .overlineText {
          font-size: 1.2rem; } }
  [is='flynt-hero-header'].flyntComponent--overlappingBackground {
    padding: 0; }
    [is='flynt-hero-header'].flyntComponent--overlappingBackground::after {
      background-color: #333;
      content: '';
      display: block;
      height: 96px;
      margin-top: -66px;
      width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-hero-header'].flyntComponent--overlappingBackground::after {
          height: 204px;
          margin-top: -170px; } }

[is='flynt-hero-image-text-slider'] {
  margin: 30px auto;
  max-width: 1920px; }
  .mainContent [is='flynt-hero-image-text-slider'].flyntComponent {
    margin-top: 30px; }
  [is='flynt-hero-image-text-slider'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 25px;
    padding-right: 25px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1292px;
        padding-left: 34px;
        padding-right: 34px; } }
  [is='flynt-hero-image-text-slider'] .slider {
    height: 100%;
    margin-top: 20px;
    padding-top: 15px; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .slider {
        margin-top: 10px;
        padding-top: 35px; } }
    [is='flynt-hero-image-text-slider'] .slider-caption {
      background: #fff;
      box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
      height: auto;
      margin: 0;
      margin-left: auto;
      max-width: 255px; }
      @media (min-width: 768px) {
        [is='flynt-hero-image-text-slider'] .slider-caption {
          height: 510px;
          max-width: 510px; } }
  [is='flynt-hero-image-text-slider'] .image-wrapper {
    height: 435px;
    max-width: 1920px;
    overflow: hidden; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .image-wrapper {
        height: 748px; } }
    [is='flynt-hero-image-text-slider'] .image-wrapper .parallax {
      height: 100%; }
    [is='flynt-hero-image-text-slider'] .image-wrapper .image {
      height: 100%;
      position: relative; }
      [is='flynt-hero-image-text-slider'] .image-wrapper .image-image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        transition: opacity 0.3s ease;
        width: 100%;
        will-change: opacity;
        z-index: -2; }
        @media (min-width: 768px) {
          [is='flynt-hero-image-text-slider'] .image-wrapper .image-image--isHidden {
            opacity: 0; } }
    [is='flynt-hero-image-text-slider'] .image-wrapper .poster {
      height: 100%;
      position: relative; }
      [is='flynt-hero-image-text-slider'] .image-wrapper .poster-image {
        height: 100%;
        position: absolute;
        object-fit: cover;
        transform: scale(1);
        transition: opacity 0.3s ease;
        width: 100%;
        will-change: opacity;
        z-index: -2; }
        @media (min-width: 768px) {
          [is='flynt-hero-image-text-slider'] .image-wrapper .poster-image {
            object-fit: cover; }
            [is='flynt-hero-image-text-slider'] .image-wrapper .poster-image--isHidden {
              opacity: 0; } }
  [is='flynt-hero-image-text-slider'] .caption {
    box-sizing: border-box;
    height: 100%;
    padding: 15px;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .caption {
        padding: 34px; } }
    [is='flynt-hero-image-text-slider'] .caption-content p,
    [is='flynt-hero-image-text-slider'] .caption-content .paragraph,
    [is='flynt-hero-image-text-slider'] .caption-content ul,
    [is='flynt-hero-image-text-slider'] .caption-content ol,
    [is='flynt-hero-image-text-slider'] .caption-content dl,
    [is='flynt-hero-image-text-slider'] .caption-content dd,
    [is='flynt-hero-image-text-slider'] .caption-content table,
    [is='flynt-hero-image-text-slider'] .caption-content figure {
      font-size: 0.75rem; }
    [is='flynt-hero-image-text-slider'] .caption-content--desktop {
      display: none; }
    [is='flynt-hero-image-text-slider'] .caption-content--desktop > :first-child:not(.overlineText), [is='flynt-hero-image-text-slider'] .caption-content--mobile > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-hero-image-text-slider'] .caption-content--desktop > :last-child:not(.overlineText), [is='flynt-hero-image-text-slider'] .caption-content--mobile > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .caption-content p,
      [is='flynt-hero-image-text-slider'] .caption-content .paragraph,
      [is='flynt-hero-image-text-slider'] .caption-content ul,
      [is='flynt-hero-image-text-slider'] .caption-content ol,
      [is='flynt-hero-image-text-slider'] .caption-content dl,
      [is='flynt-hero-image-text-slider'] .caption-content dd,
      [is='flynt-hero-image-text-slider'] .caption-content table,
      [is='flynt-hero-image-text-slider'] .caption-content figure {
        font-size: 1rem; }
      [is='flynt-hero-image-text-slider'] .caption-content--mobile {
        display: none; }
      [is='flynt-hero-image-text-slider'] .caption-content--desktop {
        display: block; } }
    [is='flynt-hero-image-text-slider'] .caption-content + .button, [is='flynt-hero-image-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-image-text-slider'] .caption-content + .gform_footer {
      margin-top: 14px;
      padding-right: 30px; }
      @media (min-width: 768px) {
        [is='flynt-hero-image-text-slider'] .caption-content + .button, [is='flynt-hero-image-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-image-text-slider'] .caption-content + .gform_footer {
          margin-top: 40px;
          padding-right: 35px; } }
      @media (min-width: 1280px) {
        [is='flynt-hero-image-text-slider'] .caption-content + .button, [is='flynt-hero-image-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-image-text-slider'] .caption-content + .gform_footer {
          margin-top: 60px; } }
    [is='flynt-hero-image-text-slider'] .caption .overlineText {
      margin-bottom: 20px; }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-hero-image-text-slider'] .caption .overlineText {
          font-size: 1.2rem; } }
  [is='flynt-hero-image-text-slider'] .swiper-pagination-titles {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .swiper-pagination-titles {
        flex-direction: row;
        padding-left: 34px;
        padding-right: 34px;
        position: absolute; } }
    @media (min-width: 1280px) {
      [is='flynt-hero-image-text-slider'] .swiper-pagination-titles {
        padding-left: 34px;
        padding-right: 34px; } }
    [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item {
      background-color: rgba(255, 255, 255, 0.65);
      border-bottom: 1px solid #d8d8d8;
      border-radius: 0;
      color: #0aa5af;
      flex-grow: 1;
      font-family: 'HelveticaNeueLTStd77-BdCn', sans-serif;
      font-size: 1.38rem;
      height: auto;
      margin: 0;
      opacity: 1;
      outline: none;
      text-align: center;
      text-transform: uppercase;
      width: auto; }
      [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item:only-child {
        visibility: hidden; }
      @media (min-width: 768px) {
        [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item {
          border: 0;
          flex-basis: 0;
          font-size: 1.88rem; } }
  @media (min-width: 768px) and (min-width: 768px) {
    [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item:hover {
      background-color: rgba(10, 165, 175, 0.3);
      color: #fff; } }
      [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item .title {
        background-color: transparent;
        display: block;
        padding: 5px 10px;
        transition: background-color 0.3s ease-out, color 0.3s ease; }
        @media (min-width: 768px) {
          [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item .title {
            padding: 10px; } }
      [is='flynt-hero-image-text-slider'] .swiper-pagination-titles--item.swiper-pagination-bullet-active {
        background-color: #0aa5af;
        color: #fff; }
  @media (min-width: 768px) {
    [is='flynt-hero-image-text-slider'] .swiper-container-horizontal .swiper-pagination-titles {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%); } }
  [is='flynt-hero-image-text-slider'] .swiper-container-horizontal .swiper-pagination-titles.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0; }
    @media (min-width: 768px) {
      [is='flynt-hero-image-text-slider'] .swiper-container-horizontal .swiper-pagination-titles.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 0.5px; } }
  [is='flynt-hero-image-text-slider'] .parallax {
    will-change: transform; }
    [is='flynt-hero-image-text-slider'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }

[is='flynt-hero-teaser'] {
  margin: 60px 0 30px; }
  @media (min-width: 768px) {
    [is='flynt-hero-teaser'] {
      margin: 102px 0 68px; } }
  [is='flynt-hero-teaser'] .hero {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 700px; }
    @media (min-width: 1024px) {
      [is='flynt-hero-teaser'] .hero {
        flex-direction: row;
        max-width: none; } }
  [is='flynt-hero-teaser'] .parallax {
    will-change: transform; }
    [is='flynt-hero-teaser'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
  [is='flynt-hero-teaser'] .column--left {
    padding-right: 0;
    height: 100%;
    margin-top: -30px;
    position: relative;
    z-index: 2; }
    [is='flynt-hero-teaser'] .column--left::after, [is='flynt-hero-teaser'] .column--left::before {
      content: none; }
    @media (min-width: 1024px) {
      [is='flynt-hero-teaser'] .column--left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: -34px;
        max-width: 578px;
        outline: none;
        width: 50%; }
        [is='flynt-hero-teaser'] .column--left .image {
          overflow: hidden;
          transform-origin: 100% 100%;
          transition: transform 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99); }
        [is='flynt-hero-teaser'] .column--left:hover .image, [is='flynt-hero-teaser'] .column--left:focus .image {
          transform: scale(1.0555); }
        [is='flynt-hero-teaser'] .column--left:hover .caption, [is='flynt-hero-teaser'] .column--left:focus .caption {
          transform: translateX(-34px); } }
    [is='flynt-hero-teaser'] .column--left .figure {
      margin: 0;
      overflow: hidden; }
      [is='flynt-hero-teaser'] .column--left .figure-image {
        transform: scale(1.2); }
  [is='flynt-hero-teaser'] .caption {
    bottom: 0;
    left: 0;
    padding: 25px;
    position: absolute;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-teaser'] .caption {
        padding: 34px 34px 60px; } }
    @media (min-width: 1024px) {
      [is='flynt-hero-teaser'] .caption {
        transition: transform 0.4s cubic-bezier(0.25, 0.74, 0.22, 0.99);
        width: 100%; } }
    [is='flynt-hero-teaser'] .caption-content {
      color: #fff; }
      [is='flynt-hero-teaser'] .caption-content > :first-child:not(.overlineText) {
        margin-top: 0;
        padding-top: 0; }
      [is='flynt-hero-teaser'] .caption-content > :last-child:not(.overlineText) {
        margin-bottom: 0;
        padding-bottom: 0; }
      @media (min-width: 1024px) {
        [is='flynt-hero-teaser'] .caption-content {
          max-width: 470px; } }
      @media (min-width: 768px) and (max-width: 1279px) {
        [is='flynt-hero-teaser'] .caption-content h3 {
          font-size: 3rem; } }
    [is='flynt-hero-teaser'] .caption .overlineText {
      margin-bottom: 20px; }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-hero-teaser'] .caption .overlineText {
          font-size: 1.2rem; } }
  [is='flynt-hero-teaser'] .column--right {
    display: flex; }
    @media (min-width: 1024px) {
      [is='flynt-hero-teaser'] .column--right {
        align-items: flex-end;
        width: 50%; } }
  [is='flynt-hero-teaser'] .hero-icon {
    align-items: center;
    background-color: #003769;
    display: flex;
    height: 60px;
    justify-content: center;
    min-width: 60px;
    width: 60px; }
    [is='flynt-hero-teaser'] .hero-icon svg,
    [is='flynt-hero-teaser'] .hero-icon img {
      height: 34px;
      width: 34px; }
    @media (min-width: 576px) {
      [is='flynt-hero-teaser'] .hero-icon {
        height: 136px;
        min-width: 136px;
        width: 136px; }
        [is='flynt-hero-teaser'] .hero-icon svg,
        [is='flynt-hero-teaser'] .hero-icon img {
          height: 60px;
          width: 60px; } }
  [is='flynt-hero-teaser'] .hero-expandable {
    align-items: center;
    background-color: #000;
    display: flex;
    height: 180px;
    justify-content: center;
    margin-top: 60px;
    min-width: 180px;
    position: relative;
    width: 180px; }
    @media (min-width: 576px) {
      [is='flynt-hero-teaser'] .hero-expandable {
        margin-top: 136px; } }
    @media (min-width: 1024px) {
      [is='flynt-hero-teaser'] .hero-expandable {
        margin-bottom: 136px;
        margin-top: 0; } }
    @media (min-width: 1280px) {
      [is='flynt-hero-teaser'] .hero-expandable {
        height: 272px;
        min-width: 272px;
        width: 272px; } }
    [is='flynt-hero-teaser'] .hero-expandable--hasModal {
      cursor: pointer; }
    [is='flynt-hero-teaser'] .hero-expandable .figure-image {
      width: 100%; }
    [is='flynt-hero-teaser'] .hero-expandable .button, [is='flynt-hero-teaser'] .hero-expandable .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-hero-teaser'] .hero-expandable .gform_footer {
      bottom: 0;
      left: 0;
      position: absolute; }
    [is='flynt-hero-teaser'] .hero-expandable:hover .button, [is='flynt-hero-teaser'] .hero-expandable:hover .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-hero-teaser'] .hero-expandable:hover .gform_footer, [is='flynt-hero-teaser'] .hero-expandable:focus .button, [is='flynt-hero-teaser'] .hero-expandable:focus .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-hero-teaser'] .hero-expandable:focus .gform_footer {
      background: #0aa5af; }
  [is='flynt-hero-teaser'] .hero-modal {
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; }
    [is='flynt-hero-teaser'] .hero-modal-inner {
      background: none;
      max-height: 100%;
      overflow-y: auto;
      touch-action: auto;
      width: 100%; }
    [is='flynt-hero-teaser'] .hero-modal .button-closeModal {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10; }
    [is='flynt-hero-teaser'] .hero-modal .centerMaxWidthContainer {
      max-width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-hero-teaser'] .hero-modal .centerMaxWidthContainer {
          max-width: 85vw; } }
      @media (min-width: 1024px) {
        [is='flynt-hero-teaser'] .hero-modal .centerMaxWidthContainer {
          max-width: 65vw; } }
      @media (min-width: 1280px) {
        [is='flynt-hero-teaser'] .hero-modal .centerMaxWidthContainer {
          max-width: 750px; } }
    [is='flynt-hero-teaser'] .hero-modal .figure {
      margin-bottom: 0; }
      @media (max-width: 767px) {
        [is='flynt-hero-teaser'] .hero-modal .figure {
          margin-left: -25px;
          margin-right: -25px; } }
  [is='flynt-hero-teaser'].flyntComponent--paddingOn {
    margin-top: 30px;
    padding-top: 30px; }
    @media (min-width: 768px) {
      [is='flynt-hero-teaser'].flyntComponent--paddingOn {
        margin-top: 68px;
        padding-top: 34px; } }
    [is='flynt-hero-teaser'].flyntComponent--paddingOn .column--left {
      margin-top: 0; }

[is='flynt-hero-video-text-slider'] {
  margin: 30px 0; }
  .mainContent [is='flynt-hero-video-text-slider'].flyntComponent {
    margin-top: 30px; }
  [is='flynt-hero-video-text-slider'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 25px;
    padding-right: 25px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 1292px;
        padding-left: 34px;
        padding-right: 34px; } }
  [is='flynt-hero-video-text-slider'] .slider {
    height: 100%;
    margin-top: 20px;
    padding-top: 15px; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .slider {
        margin-top: 10px;
        padding-top: 35px; } }
    [is='flynt-hero-video-text-slider'] .slider-caption {
      background: #fff;
      box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
      height: 255px;
      margin: 0;
      margin-left: auto;
      max-width: 255px; }
      @media (min-width: 768px) {
        [is='flynt-hero-video-text-slider'] .slider-caption {
          height: 510px;
          max-width: 510px; } }
  [is='flynt-hero-video-text-slider'] .video {
    height: 435px;
    overflow: hidden; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .video {
        height: 748px; } }
    [is='flynt-hero-video-text-slider'] .video .parallax {
      height: 100%; }
    [is='flynt-hero-video-text-slider'] .video-player {
      display: none;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: scale(1.1);
      transition: opacity 0.3s ease;
      width: 100%; }
      [is='flynt-hero-video-text-slider'] .video-player--isLoaded {
        opacity: 1; }
      @media (min-width: 768px) {
        [is='flynt-hero-video-text-slider'] .video-player {
          display: block; } }
    [is='flynt-hero-video-text-slider'] .video video {
      font-family: 'object-fit: cover';
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%; }
  [is='flynt-hero-video-text-slider'] .poster {
    height: 100%;
    position: relative; }
    [is='flynt-hero-video-text-slider'] .poster-image {
      font-family: 'object-fit: cover';
      height: 100%;
      object-fit: cover;
      position: absolute;
      transform: scale(1.1);
      transition: opacity 0.3s ease;
      width: 100%;
      will-change: opacity;
      z-index: -2; }
      @media (min-width: 768px) {
        [is='flynt-hero-video-text-slider'] .poster-image--isHidden {
          opacity: 0; } }
  [is='flynt-hero-video-text-slider'] .caption {
    box-sizing: border-box;
    height: 100%;
    padding: 15px;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .caption {
        padding: 34px; } }
    [is='flynt-hero-video-text-slider'] .caption-content p,
    [is='flynt-hero-video-text-slider'] .caption-content .paragraph,
    [is='flynt-hero-video-text-slider'] .caption-content ul,
    [is='flynt-hero-video-text-slider'] .caption-content ol,
    [is='flynt-hero-video-text-slider'] .caption-content dl,
    [is='flynt-hero-video-text-slider'] .caption-content dd,
    [is='flynt-hero-video-text-slider'] .caption-content table,
    [is='flynt-hero-video-text-slider'] .caption-content figure {
      font-size: 0.75rem; }
    [is='flynt-hero-video-text-slider'] .caption-content--desktop {
      display: none; }
    [is='flynt-hero-video-text-slider'] .caption-content--desktop > :first-child:not(.overlineText), [is='flynt-hero-video-text-slider'] .caption-content--mobile > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-hero-video-text-slider'] .caption-content--desktop > :last-child:not(.overlineText), [is='flynt-hero-video-text-slider'] .caption-content--mobile > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .caption-content p,
      [is='flynt-hero-video-text-slider'] .caption-content .paragraph,
      [is='flynt-hero-video-text-slider'] .caption-content ul,
      [is='flynt-hero-video-text-slider'] .caption-content ol,
      [is='flynt-hero-video-text-slider'] .caption-content dl,
      [is='flynt-hero-video-text-slider'] .caption-content dd,
      [is='flynt-hero-video-text-slider'] .caption-content table,
      [is='flynt-hero-video-text-slider'] .caption-content figure {
        font-size: 1rem; }
      [is='flynt-hero-video-text-slider'] .caption-content--mobile {
        display: none; }
      [is='flynt-hero-video-text-slider'] .caption-content--desktop {
        display: block; } }
    [is='flynt-hero-video-text-slider'] .caption-content + .button, [is='flynt-hero-video-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-video-text-slider'] .caption-content + .gform_footer {
      margin-top: 14px;
      padding-right: 30px; }
      @media (min-width: 768px) {
        [is='flynt-hero-video-text-slider'] .caption-content + .button, [is='flynt-hero-video-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-video-text-slider'] .caption-content + .gform_footer {
          margin-top: 40px;
          padding-right: 35px; } }
      @media (min-width: 1280px) {
        [is='flynt-hero-video-text-slider'] .caption-content + .button, [is='flynt-hero-video-text-slider'] .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-hero-video-text-slider'] .caption-content + .gform_footer {
          margin-top: 60px; } }
    [is='flynt-hero-video-text-slider'] .caption .overlineText {
      margin-bottom: 20px; }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-hero-video-text-slider'] .caption .overlineText {
          font-size: 1.2rem; } }
  [is='flynt-hero-video-text-slider'] .swiper-pagination-titles {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .swiper-pagination-titles {
        flex-direction: row;
        padding-left: 34px;
        padding-right: 34px;
        position: absolute; } }
    @media (min-width: 1280px) {
      [is='flynt-hero-video-text-slider'] .swiper-pagination-titles {
        padding-left: 34px;
        padding-right: 34px; } }
    [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item {
      background-color: rgba(255, 255, 255, 0.65);
      border-bottom: 1px solid #d8d8d8;
      border-radius: 0;
      color: #0aa5af;
      flex-grow: 1;
      font-family: 'HelveticaNeueLTStd77-BdCn', sans-serif;
      font-size: 1.38rem;
      height: auto;
      margin: 0;
      opacity: 1;
      outline: none;
      text-align: center;
      text-transform: uppercase;
      width: auto; }
      [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item:only-child {
        visibility: hidden; }
      @media (min-width: 768px) {
        [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item {
          border: 0;
          flex-basis: 0;
          font-size: 1.88rem; } }
  @media (min-width: 768px) and (min-width: 768px) {
    [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item:hover .title {
      background-color: rgba(10, 165, 175, 0.3);
      color: #fff; } }
      [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item .title {
        background-color: transparent;
        display: block;
        padding: 5px 10px;
        transition: background-color 0.3s ease-out, color 0.3s ease; }
        @media (min-width: 768px) {
          [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item .title {
            padding: 10px; } }
      [is='flynt-hero-video-text-slider'] .swiper-pagination-titles--item.swiper-pagination-bullet-active .title {
        background-color: #0aa5af;
        color: #fff; }
  @media (min-width: 768px) {
    [is='flynt-hero-video-text-slider'] .swiper-container-horizontal .swiper-pagination-titles {
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%); } }
  [is='flynt-hero-video-text-slider'] .swiper-container-horizontal .swiper-pagination-titles.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0; }
    @media (min-width: 768px) {
      [is='flynt-hero-video-text-slider'] .swiper-container-horizontal .swiper-pagination-titles.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 0.5px; } }
  [is='flynt-hero-video-text-slider'] .parallax {
    will-change: transform; }
    [is='flynt-hero-video-text-slider'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }

[is='flynt-list-download'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-list-download'] {
      margin: 68px 0; } }
  [is='flynt-list-download'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 25px;
    padding-right: 25px; }
  [is='flynt-list-download'] .preContent {
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px; }
    [is='flynt-list-download'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-list-download'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-list-download'] .preContent {
        margin-bottom: 3.4rem; } }
  [is='flynt-list-download'] .downloadList {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px; }
    [is='flynt-list-download'] .downloadList > li::before {
      content: none; }
  [is='flynt-list-download'] .download {
    border-bottom: 1px solid #999;
    margin-bottom: 0;
    position: relative; }
    [is='flynt-list-download'] .download:first-child {
      border-top: 1px solid #999; }
    [is='flynt-list-download'] .download-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      position: relative; }
    [is='flynt-list-download'] .download-link {
      padding: 1rem 0 1rem 1rem; }
      [is='flynt-list-download'] .download-link::before, [is='flynt-list-download'] .download-link::after {
        content: none; }
      @media (min-width: 768px) {
        [is='flynt-list-download'] .download-link:hover .button, [is='flynt-list-download'] .download-link:hover .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-list-download'] .download-link:hover .gform_footer {
          background-color: #0aa5af; } }
    [is='flynt-list-download'] .download-icon {
      height: 30px; }
      @media (min-width: 768px) {
        [is='flynt-list-download'] .download-icon {
          height: 34px; } }
      [is='flynt-list-download'] .download-icon--pdf svg {
        height: 30px;
        width: 30px; }
        @media (min-width: 768px) {
          [is='flynt-list-download'] .download-icon--pdf svg {
            height: 34px;
            width: 34px; } }
    [is='flynt-list-download'] .download-newTab {
      align-items: center;
      display: flex;
      flex-grow: 1;
      padding: 1rem 0; }
      [is='flynt-list-download'] .download-newTab::before, [is='flynt-list-download'] .download-newTab::after {
        content: none; }
      @media (min-width: 768px) {
        [is='flynt-list-download'] .download-newTab:hover svg path {
          fill: #00508c; }
        [is='flynt-list-download'] .download-newTab:hover .download-title {
          color: #003769; }
        [is='flynt-list-download'] .download-newTab svg path {
          transition: fill 0.2s ease; } }
    [is='flynt-list-download'] .download-title {
      color: #333;
      font-size: 0.9rem;
      font-weight: normal;
      padding-left: 15px;
      transition: color 0.2s ease; }
      @media (min-width: 768px) {
        [is='flynt-list-download'] .download-title {
          font-size: 1.31rem;
          padding-left: 35px; } }

[is='flynt-list-search-results'] .preContent {
  margin: 25px 0;
  text-align: center; }
  @media (min-width: 768px) {
    [is='flynt-list-search-results'] .preContent {
      margin: 25px 0 50px 0; } }

[is='flynt-list-search-results'] .searchForm-field {
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    [is='flynt-list-search-results'] .searchForm-field {
      flex-direction: row; } }

[is='flynt-list-search-results'] .searchForm-box {
  flex-grow: 1;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    [is='flynt-list-search-results'] .searchForm-box {
      margin-right: 20px;
      margin-bottom: 0; } }

[is='flynt-list-search-results'] .searchForm-input {
  border: 0;
  height: 100%;
  line-height: 1.85;
  margin: 0;
  min-height: 44px;
  outline: none;
  padding: 0 20px 0 14px;
  width: 100%; }
  @media (min-width: 1024px) {
    [is='flynt-list-search-results'] .searchForm-input {
      padding: 0 20px 0 24px; } }

[is='flynt-list-search-results'] .searchForm-submit {
  margin-left: auto; }

[is='flynt-list-search-results'] .searchForm input::-webkit-input-placeholder {
  color: #333; }

[is='flynt-list-search-results'] .searchForm ::-moz-placeholder {
  color: #333; }

[is='flynt-list-search-results'] .searchForm :-ms-input-placeholder {
  color: #333; }

[is='flynt-list-search-results'] .searchForm ::-webkit-input-placeholder {
  color: #333; }

[is='flynt-list-search-results'] .searchForm ::placeholder {
  color: #333; }

[is='flynt-list-search-results'] .pagination {
  margin-top: 40px;
  overflow: hidden; }
  [is='flynt-list-search-results'] .pagination-nav--previous {
    float: left;
    margin-left: 5px; }
    [is='flynt-list-search-results'] .pagination-nav--previous:after {
      display: none; }
  [is='flynt-list-search-results'] .pagination-nav--next {
    float: right; }
  [is='flynt-list-search-results'] .pagination .button, [is='flynt-list-search-results'] .pagination .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-list-search-results'] .pagination .gform_footer {
    white-space: nowrap; }
  [is='flynt-list-search-results'] .pagination .arrow {
    color: #fff;
    height: 18px;
    width: 16px; }
  [is='flynt-list-search-results'] .pagination-nav--next .arrow {
    margin-right: -5px; }
  [is='flynt-list-search-results'] .pagination-nav--previous .arrow {
    margin-left: -5px; }

[is='flynt-list-search-results'] .searchList {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  margin-top: 40px; }
  [is='flynt-list-search-results'] .searchList > li::before {
    content: none; }
  [is='flynt-list-search-results'] .searchList-item {
    border-bottom: 1px solid #ececec;
    position: relative; }
    [is='flynt-list-search-results'] .searchList-item h4 a {
      color: #0aa5af; }
    [is='flynt-list-search-results'] .searchList-item:last-child {
      border: 0; }

[is='flynt-list-search-results'] .post > :first-child:not(.overlineText) {
  margin-top: 0;
  padding-top: 0; }

[is='flynt-list-search-results'] .post > :last-child:not(.overlineText) {
  margin-bottom: 0;
  padding-bottom: 0; }

[is='flynt-list-search-results'] .post-footer {
  text-align: right;
  margin-bottom: 20px; }

[is='flynt-list-search-results'] .noResults {
  margin-top: 20px;
  text-align: center; }

[is='flynt-map-locations'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-map-locations'] {
      margin: 68px 0; } }
  .mainContent [is='flynt-map-locations'].flyntComponent {
    margin-top: 30px; }
    @media (min-width: 768px) {
      .mainContent [is='flynt-map-locations'].flyntComponent {
        margin-top: 68px; } }
  [is='flynt-map-locations'] .container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1292px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .container {
        padding-left: 34px;
        padding-right: 34px; } }
    [is='flynt-map-locations'] .container-slider {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 1292px;
      padding-left: 40px;
      padding-right: 40px; }
  [is='flynt-map-locations'] .preContent {
    margin-bottom: 1.5rem; }
    [is='flynt-map-locations'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-map-locations'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .preContent {
        margin-bottom: 3.4rem; } }
    [is='flynt-map-locations'] .preContent h1, [is='flynt-map-locations'] .preContent h2, [is='flynt-map-locations'] .preContent h3, [is='flynt-map-locations'] .preContent h4, [is='flynt-map-locations'] .preContent h5, [is='flynt-map-locations'] .preContent h6, [is='flynt-map-locations'] .preContent .h1, [is='flynt-map-locations'] .preContent .h2, [is='flynt-map-locations'] .preContent .h3, [is='flynt-map-locations'] .preContent .h4, [is='flynt-map-locations'] .preContent .h5, [is='flynt-map-locations'] .preContent .h6 {
      margin-bottom: 1rem; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .preContent h1, [is='flynt-map-locations'] .preContent h2, [is='flynt-map-locations'] .preContent h3, [is='flynt-map-locations'] .preContent h4, [is='flynt-map-locations'] .preContent h5, [is='flynt-map-locations'] .preContent h6, [is='flynt-map-locations'] .preContent .h1, [is='flynt-map-locations'] .preContent .h2, [is='flynt-map-locations'] .preContent .h3, [is='flynt-map-locations'] .preContent .h4, [is='flynt-map-locations'] .preContent .h5, [is='flynt-map-locations'] .preContent .h6 {
          margin-bottom: 1.8rem; } }
  [is='flynt-map-locations'] .map-filters {
    margin-bottom: 35px; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .map-filters {
        margin-bottom: 68px; } }
  [is='flynt-map-locations'] .map-wrapper {
    height: 300px;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .map-wrapper {
        height: 464px;
        margin-bottom: 68px; } }
    [is='flynt-map-locations'] .map-wrapper [target=_blank]::before, [is='flynt-map-locations'] .map-wrapper [target=_blank]::after {
      content: none; }
  [is='flynt-map-locations'] .map-consent-layer {
    background: url("../assets/images/map-overlay.jpg") no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    line-height: 30px;
    margin: 0 auto;
    height: 550px;
    margin-bottom: 30px; }
    [is='flynt-map-locations'] .map-consent-layer .overlineText {
      line-height: 1.5;
      padding: 0;
      background: transparent;
      text-transform: none;
      font-size: 1rem;
      font-family: 'HelveticaNowText', sans-serif; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .map-consent-layer .overlineText {
          font-size: 1.2rem; } }
    [is='flynt-map-locations'] .map-consent-layer .container {
      max-width: 900px; }
    [is='flynt-map-locations'] .map-consent-layer .button:after, [is='flynt-map-locations'] .map-consent-layer .gform_wrapper .gform_footer:after, .gform_wrapper [is='flynt-map-locations'] .map-consent-layer .gform_footer:after {
      background-image: url("../assets/icons/turquoise-arrow-sprite.svg"); }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .map-consent-layer {
        height: 464px;
        margin-bottom: 68px; } }
    [is='flynt-map-locations'] .map-consent-layer a,
    [is='flynt-map-locations'] .map-consent-layer .button,
    [is='flynt-map-locations'] .map-consent-layer .gform_wrapper .gform_footer,
    .gform_wrapper [is='flynt-map-locations'] .map-consent-layer .gform_footer {
      color: #0aa5af; }
      [is='flynt-map-locations'] .map-consent-layer a:hover,
      [is='flynt-map-locations'] .map-consent-layer .button:hover,
      [is='flynt-map-locations'] .map-consent-layer .gform_wrapper .gform_footer:hover,
      .gform_wrapper [is='flynt-map-locations'] .map-consent-layer .gform_footer:hover {
        color: #9ddbdf; }
    [is='flynt-map-locations'] .map-consent-layer:hover .button, [is='flynt-map-locations'] .map-consent-layer:hover .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-map-locations'] .map-consent-layer:hover .gform_footer {
      color: #9ddbdf; }
      [is='flynt-map-locations'] .map-consent-layer:hover .button:after, [is='flynt-map-locations'] .map-consent-layer:hover .gform_wrapper .gform_footer:after, .gform_wrapper [is='flynt-map-locations'] .map-consent-layer:hover .gform_footer:after {
        background-image: url("../assets/icons/turquoise-arrow-sprite.svg");
        background-position: 100%; }
  [is='flynt-map-locations'] .map-controls {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.2s ease; }
    [is='flynt-map-locations'] .map-controls.map-controls-initialized {
      opacity: 1; }
    [is='flynt-map-locations'] .map-controls .map-buttons {
      background-color: #fff;
      border: 0;
      bottom: 20px;
      cursor: pointer;
      height: 30px;
      max-width: none;
      min-width: auto;
      position: relative;
      right: 20px;
      width: 30px; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .map-controls .map-buttons {
          height: 45px;
          width: 45px; } }
      [is='flynt-map-locations'] .map-controls .map-buttons::before, [is='flynt-map-locations'] .map-controls .map-buttons::after {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease;
        width: 100%; }
      [is='flynt-map-locations'] .map-controls .map-buttons::after {
        opacity: 0; }
      [is='flynt-map-locations'] .map-controls .map-buttons:hover::before, [is='flynt-map-locations'] .map-controls .map-buttons:focus::before {
        opacity: 0; }
      [is='flynt-map-locations'] .map-controls .map-buttons:hover::after, [is='flynt-map-locations'] .map-controls .map-buttons:focus::after {
        opacity: 1; }
      [is='flynt-map-locations'] .map-controls .map-buttons.zoomIn::before {
        background-image: url("../assets/icons/zoom_plus.svg"); }
      [is='flynt-map-locations'] .map-controls .map-buttons.zoomIn::after {
        background-image: url("../assets/icons/zoom_plus_hover.svg"); }
      [is='flynt-map-locations'] .map-controls .map-buttons.zoomOut {
        margin-top: 6px; }
        [is='flynt-map-locations'] .map-controls .map-buttons.zoomOut::before {
          background-image: url("../assets/icons/zoom_minus.svg"); }
        [is='flynt-map-locations'] .map-controls .map-buttons.zoomOut::after {
          background-image: url("../assets/icons/zoom_minus_hover.svg"); }
  [is='flynt-map-locations'] .filters {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .filters {
        flex-direction: row; } }
    [is='flynt-map-locations'] .filters-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 845px; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .filters-header {
          flex-direction: row; } }
    [is='flynt-map-locations'] .filters-title {
      color: #333;
      font-size: 0.875rem;
      margin-bottom: 1rem; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .filters-title {
          font-size: 1rem;
          margin-bottom: 1.5rem; } }
    [is='flynt-map-locations'] .filters-clearBtn {
      display: none;
      font-family: 'HelveticaNowText', sans-serif;
      font-size: 0.88rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 1rem;
      text-transform: none;
      white-space: nowrap; }
      [is='flynt-map-locations'] .filters-clearBtn::after {
        background-image: url("../assets/icons/icon-close-sprite.svg");
        background-size: 200% 100%;
        height: 10px;
        width: 10px; }
      [is='flynt-map-locations'] .filters-clearBtn--visible {
        display: block; }
    [is='flynt-map-locations'] .filters-select {
      padding: 13px; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .filters-select {
          font-size: 1rem;
          max-width: 408px;
          padding: 16px;
          width: 100%; } }
      [is='flynt-map-locations'] .filters-select:first-child {
        margin-bottom: 1rem; }
        @media (min-width: 768px) {
          [is='flynt-map-locations'] .filters-select:first-child {
            margin-bottom: 0;
            margin-right: 2rem; } }
  [is='flynt-map-locations'] .slider-button {
    cursor: default;
    height: 30px;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 30px; }
    [is='flynt-map-locations'] .slider-button.visible {
      cursor: pointer;
      opacity: 1; }
    [is='flynt-map-locations'] .slider-button.swiper-button-disabled {
      opacity: 0.5; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .slider-button {
        height: 34px;
        width: 34px; } }
    [is='flynt-map-locations'] .slider-button--prev {
      left: 0; }
      @media (min-width: 1280px) {
        [is='flynt-map-locations'] .slider-button--prev {
          left: 34px; } }
    [is='flynt-map-locations'] .slider-button--next {
      right: 0; }
      @media (min-width: 1280px) {
        [is='flynt-map-locations'] .slider-button--next {
          right: 34px; } }
  [is='flynt-map-locations'] .location-bannerImage {
    padding-bottom: 30px; }
    @media (min-width: 768px) {
      [is='flynt-map-locations'] .location-bannerImage {
        padding-bottom: 68px; } }
  @media (min-width: 768px) {
    [is='flynt-map-locations'] .location-content--column2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%; }
      [is='flynt-map-locations'] .location-content--column2 > * {
        width: calc((50%) - (1 / 2 * 10px)); }
        [is='flynt-map-locations'] .location-content--column2 > *:nth-child(n) {
          margin-right: 10px;
          margin-top: 0; }
        [is='flynt-map-locations'] .location-content--column2 > *:nth-child(1n + 3) {
          margin-top: 22px; }
        [is='flynt-map-locations'] .location-content--column2 > *:nth-child(2n) {
          margin-right: 0; }
        [is='flynt-map-locations'] .location-content--column2 > *:last-child {
          margin-right: 0; } }
  [is='flynt-map-locations'] .location-content--description {
    margin-bottom: 22px; }
    [is='flynt-map-locations'] .location-content--description > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-map-locations'] .location-content--description > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    [is='flynt-map-locations'] .location-content--description .content > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-map-locations'] .location-content--description .content > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    [is='flynt-map-locations'] .location-content--description .content p,
    [is='flynt-map-locations'] .location-content--description .content .paragraph,
    [is='flynt-map-locations'] .location-content--description .content ul,
    [is='flynt-map-locations'] .location-content--description .content ol,
    [is='flynt-map-locations'] .location-content--description .content dl,
    [is='flynt-map-locations'] .location-content--description .content dd,
    [is='flynt-map-locations'] .location-content--description .content table,
    [is='flynt-map-locations'] .location-content--description .content figure {
      margin-bottom: 1rem; }
      @media (min-width: 768px) {
        [is='flynt-map-locations'] .location-content--description .content p,
        [is='flynt-map-locations'] .location-content--description .content .paragraph,
        [is='flynt-map-locations'] .location-content--description .content ul,
        [is='flynt-map-locations'] .location-content--description .content ol,
        [is='flynt-map-locations'] .location-content--description .content dl,
        [is='flynt-map-locations'] .location-content--description .content dd,
        [is='flynt-map-locations'] .location-content--description .content table,
        [is='flynt-map-locations'] .location-content--description .content figure {
          margin-bottom: 2rem; } }
  [is='flynt-map-locations'] .location-content--image {
    max-width: 476px; }
  [is='flynt-map-locations'] .location-title {
    max-width: 400px; }

#markerLayer img {
  bottom: 0;
  left: 50% !important;
  top: auto !important;
  transform: translateX(-50%);
  transition: height 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), width 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

@media screen and (max-width: 600px) {
  #wpadminbar {
    position: fixed; } }

[is='flynt-navigation-burger'] {
  height: 60px; }
  @media (min-width: 1280px) {
    [is='flynt-navigation-burger'] {
      display: none; } }
  [is='flynt-navigation-burger'] .container {
    align-items: flex-start;
    background-color: #fff;
    display: flex;
    height: 60px;
    justify-content: space-between;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: height 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
    z-index: 99; }
    .admin-bar [is='flynt-navigation-burger'] .container {
      top: 46px; }
      @media (min-width: 783px) {
        .admin-bar [is='flynt-navigation-burger'] .container {
          top: 32px; } }
    [is='flynt-navigation-burger'] .container-right {
      align-items: flex-start;
      display: flex; }
  .mainHeader-menuIsSticky [is='flynt-navigation-burger'] .container {
    background-color: #fff;
    box-shadow: 0 0 4px rgba(102, 102, 102, 0.2); }
  [is='flynt-navigation-burger'] .logo {
    display: block;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    z-index: 99; }
    [is='flynt-navigation-burger'] .logo-image {
      height: 100%;
      width: auto; }
  [is='flynt-navigation-burger'] .hamburger {
    background-color: #fff;
    cursor: pointer;
    display: block;
    height: 45px;
    margin-right: -12px;
    outline: 0;
    position: relative;
    transition: background-color 0.2s ease-in-out;
    width: 45px;
    z-index: 99; }
    [is='flynt-navigation-burger'] .hamburger::before, [is='flynt-navigation-burger'] .hamburger::after {
      content: ''; }
    [is='flynt-navigation-burger'] .hamburger::before, [is='flynt-navigation-burger'] .hamburger::after, [is='flynt-navigation-burger'] .hamburger-barPrimary, [is='flynt-navigation-burger'] .hamburger-barSecondary {
      background-color: #0aa5af;
      display: block;
      height: 2px;
      left: 50%;
      margin-left: -0.625rem;
      position: absolute;
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
      width: 1.25rem; }
    [is='flynt-navigation-burger'] .hamburger::before {
      top: 16px; }
    [is='flynt-navigation-burger'] .hamburger-barPrimary, [is='flynt-navigation-burger'] .hamburger-barSecondary {
      top: 22px; }
    [is='flynt-navigation-burger'] .hamburger::after {
      top: 28px; }
  [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger {
    background-color: #fff; }
    [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger::before, [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger::after {
      opacity: 0; }
    [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger::before {
      transform: scale(0) translateY(8px); }
    [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger::after {
      transform: scale(0) translateY(-8px); }
    [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger-barPrimary {
      background-color: #0aa5af;
      transform: rotate(45deg); }
    [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .hamburger-barSecondary {
      background-color: #0aa5af;
      transform: rotate(-45deg); }
  [is='flynt-navigation-burger'] .menu {
    backface-visibility: hidden;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(102, 102, 102, 0.2);
    color: #333;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding-top: 60px;
    position: fixed;
    top: 0;
    transform: translateX(102%);
    transition: transform 0.45s cubic-bezier(0.42, 0.22, 0.13, 0.89);
    width: 100%;
    z-index: 98; }
    .admin-bar [is='flynt-navigation-burger'] .menu {
      padding-top: 120px; }
      @media (min-width: 783px) {
        .admin-bar [is='flynt-navigation-burger'] .menu {
          padding-top: 100px; } }
    [is='flynt-navigation-burger'] .menu-scroll {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      overflow-y: auto;
      padding-top: 30px;
      touch-action: auto; }
      [is='flynt-navigation-burger'] .menu-scroll > li::before {
        content: none; }
    [is='flynt-navigation-burger'] .menu-item--current .menu-link {
      color: #0aa5af;
      font-weight: 700; }
    [is='flynt-navigation-burger'] .menu-item--current > .menu-toggler::before, [is='flynt-navigation-burger'] .menu-item--current > .menu-toggler::after {
      background-color: #0aa5af; }
    [is='flynt-navigation-burger'] .menu-sub {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      flex-direction: column;
      margin-left: -25px;
      margin-right: -25px;
      padding: 0 25px; }
      [is='flynt-navigation-burger'] .menu-sub > li::before {
        content: none; }
      @media (min-width: 768px) {
        [is='flynt-navigation-burger'] .menu-sub {
          margin-left: -34px;
          margin-right: -34px;
          padding-left: 34px;
          padding-right: 34px; } }
    [is='flynt-navigation-burger'] .menu-sub-child {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      flex-direction: column; }
      [is='flynt-navigation-burger'] .menu-sub-child > li::before {
        content: none; }
    [is='flynt-navigation-burger'] .menu-footer {
      border-top: 1px solid #f2f2f2;
      margin: 0;
      order: 90;
      padding-bottom: 12px;
      padding-top: 22px; }
      [is='flynt-navigation-burger'] .menu-footer .description {
        display: block;
        line-height: 1.125rem;
        margin-bottom: 15px; }
      [is='flynt-navigation-burger'] .menu-footer .button, [is='flynt-navigation-burger'] .menu-footer .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-burger'] .menu-footer .gform_footer {
        color: #333;
        padding-right: 30px; }
        @media (min-width: 768px) {
          [is='flynt-navigation-burger'] .menu-footer .button, [is='flynt-navigation-burger'] .menu-footer .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-burger'] .menu-footer .gform_footer {
            padding-right: 35px; } }
        [is='flynt-navigation-burger'] .menu-footer .button--external::after, [is='flynt-navigation-burger'] .menu-footer .button[target=_blank]::after, [is='flynt-navigation-burger'] .menu-footer .gform_wrapper .gform_footer[target=_blank]::after, .gform_wrapper [is='flynt-navigation-burger'] .menu-footer .gform_footer[target=_blank]::after {
          background-image: url("../assets/icons/arrow-external-black.svg");
          background-size: 100%;
          opacity: 1; }
        [is='flynt-navigation-burger'] .menu-footer .button--external::before, [is='flynt-navigation-burger'] .menu-footer .button[target=_blank]::before, [is='flynt-navigation-burger'] .menu-footer .gform_wrapper .gform_footer[target=_blank]::before, .gform_wrapper [is='flynt-navigation-burger'] .menu-footer .gform_footer[target=_blank]::before {
          display: none; }
    [is='flynt-navigation-burger'] .menu-item {
      margin: 12px 0 0;
      padding: 0 25px; }
      @media (min-width: 768px) {
        [is='flynt-navigation-burger'] .menu-item {
          padding-left: 34px;
          padding-right: 34px; } }
      [is='flynt-navigation-burger'] .menu-item:last-child {
        margin-bottom: 95px; }
      [is='flynt-navigation-burger'] .menu-item::after {
        background: #979797;
        content: '';
        display: block;
        height: 1px;
        width: 100%; }
    [is='flynt-navigation-burger'] .menu-sub-item {
      border-top: 1px solid #f2f2f2;
      margin: 0; }
      [is='flynt-navigation-burger'] .menu-sub-item .menu-sub-item .menu-sub-link {
        margin: 0;
        padding-left: 30px; }
      [is='flynt-navigation-burger'] .menu-sub-item .menu-sub-item.menu-sub-item--current {
        background: #f2f2f2; }
      [is='flynt-navigation-burger'] .menu-sub-item--current > .menu-sub-link {
        color: #0aa5af;
        font-weight: 700; }
      [is='flynt-navigation-burger'] .menu-sub-item--current > .menu-toggler::before, [is='flynt-navigation-burger'] .menu-sub-item--current > .menu-toggler::after {
        background-color: #0aa5af; }
      [is='flynt-navigation-burger'] .menu-sub-item .menu-toggler {
        right: -25px; }
        @media (min-width: 768px) {
          [is='flynt-navigation-burger'] .menu-sub-item .menu-toggler {
            right: -34px; } }
    [is='flynt-navigation-burger'] .menu-link {
      color: #333;
      display: block;
      font-size: 1.25rem;
      font-weight: 400;
      margin: 0 -25px;
      padding: 8px 25px;
      text-align: left;
      text-decoration: none;
      transition: color 0.2s ease-out; }
    [is='flynt-navigation-burger'] .menu-sub-link {
      color: #333;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      margin: 0 -25px;
      padding: 11px 25px;
      transition: color 0.2s ease-out; }
    [is='flynt-navigation-burger'] .menu-toggler {
      cursor: pointer;
      height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      width: 84px;
      z-index: 2; }
      @media (min-width: 768px) {
        [is='flynt-navigation-burger'] .menu-toggler {
          width: 94px; } }
      [is='flynt-navigation-burger'] .menu-toggler::before, [is='flynt-navigation-burger'] .menu-toggler::after {
        background-color: #333;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        margin: -1px 0 0 -5px;
        position: absolute;
        top: 50%;
        transition: transform 0.2s ease-in-out;
        width: 10px; }
      [is='flynt-navigation-burger'] .menu-toggler::before {
        transform: translateX(-3px) rotate(45deg); }
      [is='flynt-navigation-burger'] .menu-toggler::after {
        transform: translateX(3px) rotate(-45deg); }
      [is='flynt-navigation-burger'] .menu-toggler[aria-expanded='true']::before {
        transform: translateX(-3px) rotate(-45deg); }
      [is='flynt-navigation-burger'] .menu-toggler[aria-expanded='true']::after {
        transform: translateX(3px) rotate(45deg); }
  [is='flynt-navigation-burger'].flyntComponent-menuIsOpen .menu {
    transform: translateX(0); }
  [is='flynt-navigation-burger'] .menu-lang {
    position: relative;
    z-index: 99; }
    [is='flynt-navigation-burger'] .menu-lang > * {
      margin: 7px 0 0;
      padding: 0 5px 0 0; }
    [is='flynt-navigation-burger'] .menu-lang ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0; }
      [is='flynt-navigation-burger'] .menu-lang ul > li::before {
        content: none; }
    [is='flynt-navigation-burger'] .menu-lang a {
      color: #878787;
      font-size: 1rem;
      font-weight: 400;
      padding: 3px 12px 0 7px;
      text-transform: uppercase; }
      [is='flynt-navigation-burger'] .menu-lang a:hover {
        color: #003769; }
  [is='flynt-navigation-burger'] .menu-search {
    margin-top: 8px;
    position: relative;
    z-index: 99; }
    [is='flynt-navigation-burger'] .menu-search svg {
      width: 16px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translateX(-50%); }
    [is='flynt-navigation-burger'] .menu-search:after {
      display: none; }
    [is='flynt-navigation-burger'] .menu-search .menu-link {
      padding: 0; }

[is='flynt-navigation-footer'] {
  overflow: hidden;
  padding-top: 60px; }
  @media (min-width: 768px) {
    [is='flynt-navigation-footer'] {
      padding-top: 102px; } }
  [is='flynt-navigation-footer'] .footer {
    display: flex;
    flex-direction: column; }
    @media (min-width: 1024px) {
      [is='flynt-navigation-footer'] .footer {
        flex-direction: row; } }
    [is='flynt-navigation-footer'] .footer a:focus {
      outline: none; }
    [is='flynt-navigation-footer'] .footer h6, [is='flynt-navigation-footer'] .footer-title {
      font-size: 1.13rem;
      line-height: 1.14;
      margin-bottom: 22px;
      padding-top: 0; }
      @media (max-width: 767px) {
        [is='flynt-navigation-footer'] .footer h6, [is='flynt-navigation-footer'] .footer-title {
          font-size: 1.125rem;
          margin-bottom: 0.75rem; } }
      [is='flynt-navigation-footer'] .footer h6 br, [is='flynt-navigation-footer'] .footer-title br {
        display: none; }
    [is='flynt-navigation-footer'] .footer > * {
      flex-grow: 1; }
  [is='flynt-navigation-footer'] .footer-left {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1.44rem 0 1.56rem;
    position: relative; }
    @media (min-width: 576px) {
      [is='flynt-navigation-footer'] .footer-left {
        flex-direction: row; } }
    @media (min-width: 1024px) {
      [is='flynt-navigation-footer'] .footer-left {
        padding: 2rem 2.13rem 2.06rem 0; } }
    @media (min-width: 1280px) {
      [is='flynt-navigation-footer'] .footer-left {
        padding: 2rem 4.63rem 2.06rem 0; } }
    [is='flynt-navigation-footer'] .footer-left > * {
      flex-grow: 1; }
    [is='flynt-navigation-footer'] .footer-left p,
    [is='flynt-navigation-footer'] .footer-left a {
      font-size: 0.88rem; }
    [is='flynt-navigation-footer'] .footer-left .menu-item a {
      display: block;
      line-height: 1.7; }
  @media (max-width: 575px) {
    [is='flynt-navigation-footer'] .footer-content {
      margin-bottom: 2rem; } }
  @media (min-width: 576px) {
    [is='flynt-navigation-footer'] .footer-content {
      max-width: 72%;
      padding-right: 40px; } }
  [is='flynt-navigation-footer'] .footer-content p {
    line-height: 1.7;
    margin: 0; }
  [is='flynt-navigation-footer'] .footer-content p + p {
    margin-top: 23px; }
  [is='flynt-navigation-footer'] .footer-content a {
    color: #fff;
    font-weight: 400; }
    @media (min-width: 1024px) {
      [is='flynt-navigation-footer'] .footer-content a:not([href^='tel:']):hover {
        color: #003769; } }
  [is='flynt-navigation-footer'] .footer-right {
    background-color: #003769;
    display: flex;
    margin-left: -34px;
    margin-right: -34px;
    padding: 1.44rem 34px 1.56rem;
    position: relative; }
    @media (min-width: 1024px) {
      [is='flynt-navigation-footer'] .footer-right {
        margin: 0;
        max-width: 36%;
        padding: 2rem 0 4.13rem 2.06rem; }
        [is='flynt-navigation-footer'] .footer-right::before {
          background-color: #003769;
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 50vw;
          z-index: 0; } }
    [is='flynt-navigation-footer'] .footer-right p {
      font-size: 0.88rem;
      line-height: 1.7; }
    @media (min-width: 1280px) {
      [is='flynt-navigation-footer'] .footer-right {
        padding: 2rem 0 2.06rem 4.63rem; } }
  [is='flynt-navigation-footer'] .footer-social {
    position: relative;
    z-index: 2; }
    @media (max-width: 767px) {
      [is='flynt-navigation-footer'] .footer-social .footer-title {
        margin-bottom: 1.63rem; } }
  [is='flynt-navigation-footer'] .footer-info {
    position: relative;
    z-index: 2; }
    [is='flynt-navigation-footer'] .footer-info p {
      margin-bottom: 1.45rem; }
      [is='flynt-navigation-footer'] .footer-info p:last-child {
        margin-bottom: 0; }
    [is='flynt-navigation-footer'] .footer-info a {
      color: #fff;
      font-weight: 400; }
      @media (min-width: 1024px) {
        [is='flynt-navigation-footer'] .footer-info a:not(.button):hover {
          text-decoration: underline; } }
    [is='flynt-navigation-footer'] .footer-info .button, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer {
      color: #fff;
      transition: opacity 0.2s ease-in-out; }
      @media (min-width: 768px) {
        [is='flynt-navigation-footer'] .footer-info .button, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer {
          font-size: 1.31rem;
          padding-right: 35px; } }
      @media (min-width: 1024px) {
        [is='flynt-navigation-footer'] .footer-info .button:hover, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer:hover, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer:hover, [is='flynt-navigation-footer'] .footer-info .button:focus, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer:focus, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer:focus {
          color: #0aa5af; } }
      [is='flynt-navigation-footer'] .footer-info .button::before, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer::before, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer::before {
        display: none; }
      [is='flynt-navigation-footer'] .footer-info .button::after, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer::after, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer::after {
        background-image: url("../assets/icons/arrow-right-white-large.svg");
        opacity: 1; }
        @media (min-width: 768px) {
          [is='flynt-navigation-footer'] .footer-info .button::after, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer::after, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer::after {
            margin-top: -1px; } }
      [is='flynt-navigation-footer'] .footer-info .button--external::after, [is='flynt-navigation-footer'] .footer-info .button[target=_blank]::after, [is='flynt-navigation-footer'] .footer-info .gform_wrapper .gform_footer[target=_blank]::after, .gform_wrapper [is='flynt-navigation-footer'] .footer-info .gform_footer[target=_blank]::after {
        background-image: url("../assets/icons/arrow-external-sprite-footer.svg");
        background-size: 200% 100%;
        height: 17px;
        width: 17px; }
  [is='flynt-navigation-footer'] .social {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    [is='flynt-navigation-footer'] .social svg {
      width: 44px; }
      @media (min-width: 768px) {
        [is='flynt-navigation-footer'] .social svg {
          width: 49px; } }
    [is='flynt-navigation-footer'] .social-link {
      padding-right: 0;
      margin: 0 30px 10px 0;
      transition: opacity 0.2s ease-in-out; }
      [is='flynt-navigation-footer'] .social-link::after, [is='flynt-navigation-footer'] .social-link::before {
        content: none; }
      @media (min-width: 768px) {
        [is='flynt-navigation-footer'] .social-link {
          margin-right: 36px; } }
      @media (min-width: 1024px) {
        [is='flynt-navigation-footer'] .social-link:hover, [is='flynt-navigation-footer'] .social-link:focus {
          opacity: 0.8; } }
      [is='flynt-navigation-footer'] .social-link:last-child {
        margin-right: 0; }
  [is='flynt-navigation-footer'] .menu {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0; }
    [is='flynt-navigation-footer'] .menu > li::before {
      content: none; }
    [is='flynt-navigation-footer'] .menu-item {
      margin: 0; }
    [is='flynt-navigation-footer'] .menu-link {
      color: #fff;
      font-weight: 400; }
      [is='flynt-navigation-footer'] .menu-link:after {
        background-image: none; }
      @media (max-width: 767px) {
        [is='flynt-navigation-footer'] .menu-link {
          line-height: 1.85; } }
      @media (min-width: 1024px) {
        [is='flynt-navigation-footer'] .menu-link:hover, [is='flynt-navigation-footer'] .menu-link:focus {
          color: #003769; } }
  [is='flynt-navigation-footer'] .footer-note-inner {
    border-top: 1px solid #979797;
    padding-bottom: 18px;
    padding-top: 18px; }
    [is='flynt-navigation-footer'] .footer-note-inner > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-navigation-footer'] .footer-note-inner > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-navigation-footer'] .footer-note-inner {
        padding-bottom: 24px;
        padding-top: 24px; } }
    [is='flynt-navigation-footer'] .footer-note-inner p {
      font-size: 0.88rem; }

[is='flynt-navigation-main'] {
  display: none; }
  @media (min-width: 1280px) {
    [is='flynt-navigation-main'] {
      display: block;
      height: 102px; } }
  [is='flynt-navigation-main'] .wrapper {
    background-color: #fff;
    height: 102px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: height 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    z-index: 3; }
  [is='flynt-navigation-main'] .container {
    display: flex;
    height: 100%;
    justify-content: space-between; }
  .mainHeader-menuIsSticky [is='flynt-navigation-main'] .wrapper {
    box-shadow: 0 0 4px rgba(102, 102, 102, 0.2);
    height: 86px;
    position: fixed; }
  [is='flynt-navigation-main'] .logo {
    display: block;
    flex-shrink: 0;
    height: 100%; }
    [is='flynt-navigation-main'] .logo-image {
      height: 100%;
      width: auto; }
  [is='flynt-navigation-main'] .menu {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    margin-right: -20px;
    padding-left: 40px; }
    [is='flynt-navigation-main'] .menu > li::before {
      content: none; }
    [is='flynt-navigation-main'] .menu-drop-wrap {
      background: #fff;
      box-shadow: inset 0 4px 4px -4px rgba(102, 102, 102, 0.2);
      display: none;
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 3; }
      [is='flynt-navigation-main'] .menu-drop-wrap::after {
        background: linear-gradient(to bottom, rgba(102, 102, 102, 0.1) 0%, rgba(102, 102, 102, 0) 100%);
        content: '';
        display: block;
        height: 3px;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%; }
    [is='flynt-navigation-main'] .menu-drop {
      list-style: none;
      margin: 0 auto;
      opacity: 0;
      padding-bottom: 55px;
      padding-top: 55px;
      transition: opacity 0.3s ease-in; }
      [is='flynt-navigation-main'] .menu-drop > li::before {
        content: none; }
      [is='flynt-navigation-main'] .menu-drop-item .menu-drop-link {
        color: #333; }
        [is='flynt-navigation-main'] .menu-drop-item .menu-drop-link:hover, [is='flynt-navigation-main'] .menu-drop-item .menu-drop-link:focus {
          color: #0aa5af; }
      [is='flynt-navigation-main'] .menu-drop-item--current > .menu-drop-link {
        color: #0aa5af; }
    [is='flynt-navigation-main'] .menu-sub-drop {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      padding: 20px 0 40px; }
      [is='flynt-navigation-main'] .menu-sub-drop > li::before {
        content: none; }
      [is='flynt-navigation-main'] .menu-sub-drop .menu-drop-link {
        font-weight: 400; }
    [is='flynt-navigation-main'] .menu-item {
      align-items: center;
      display: flex;
      margin-bottom: 0;
      position: static; }
      [is='flynt-navigation-main'] .menu-item .menu-drop {
        display: flex;
        flex-wrap: wrap; }
        [is='flynt-navigation-main'] .menu-item .menu-drop > .menu-drop-item {
          flex-grow: 1;
          padding-left: 24px; }
          @media (min-width: 1280px) {
            [is='flynt-navigation-main'] .menu-item .menu-drop > .menu-drop-item {
              min-width: 16.66667%; } }
          [is='flynt-navigation-main'] .menu-item .menu-drop > .menu-drop-item:first-child {
            padding-left: 0; }
          [is='flynt-navigation-main'] .menu-item .menu-drop > .menu-drop-item > .menu-drop-link {
            border-bottom: 1px solid #979797;
            display: block;
            padding-bottom: 10px; }
    [is='flynt-navigation-main'] .menu-link {
      color: #333;
      display: inline-block;
      font-size: 1.3125rem;
      font-weight: 400;
      padding: 10px 20px;
      position: relative;
      text-decoration: none;
      transition: color 0.2s ease-out; }
      [is='flynt-navigation-main'] .menu-link:hover, [is='flynt-navigation-main'] .menu-link:focus {
        color: #0aa5af; }
      [is='flynt-navigation-main'] .menu-link::after {
        background: #0aa5af;
        bottom: 10px;
        content: '';
        height: 2px;
        left: 20px;
        opacity: 0;
        position: absolute;
        right: 20px;
        transform: translateY(10px);
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out; }
    [is='flynt-navigation-main'] .menu-item--current .menu-link {
      color: #0aa5af; }
    [is='flynt-navigation-main'] .menu-item--expanded .menu-drop-wrap {
      z-index: 4; }
    [is='flynt-navigation-main'] .menu-item--expanded .menu-link::after {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.15s; }
    [is='flynt-navigation-main'] .menu-item--visible .menu-drop {
      opacity: 1; }
    [is='flynt-navigation-main'] .menu .menu-drop--hasChildren .menu-footer {
      border-top: 1px solid #979797; }
    [is='flynt-navigation-main'] .menu-footer {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin: 0;
      order: 90;
      padding-top: 10px;
      width: 100%; }
      [is='flynt-navigation-main'] .menu-footer .description {
        font-size: 0.875rem;
        padding: 5px 20px 5px 0; }
      [is='flynt-navigation-main'] .menu-footer .button, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer {
        color: #333; }
        @media (min-width: 768px) {
          [is='flynt-navigation-main'] .menu-footer .button, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer {
            padding-right: 35px; }
            [is='flynt-navigation-main'] .menu-footer .button:hover, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer:hover, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer:hover, [is='flynt-navigation-main'] .menu-footer .button:focus, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer:focus, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer:focus {
              color: #003769; } }
        [is='flynt-navigation-main'] .menu-footer .button--external::after, [is='flynt-navigation-main'] .menu-footer .button[target=_blank]::after, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer[target=_blank]::after, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer[target=_blank]::after {
          background-image: url("../assets/icons/arrow-external-black.svg");
          background-size: 100%; }
        @media (min-width: 768px) {
          [is='flynt-navigation-main'] .menu-footer .button--external:hover::after, [is='flynt-navigation-main'] .menu-footer .button--external:focus::after, [is='flynt-navigation-main'] .menu-footer .button[target=_blank]:hover::after, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer[target=_blank]:hover::after, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer[target=_blank]:hover::after, [is='flynt-navigation-main'] .menu-footer .button[target=_blank]:focus::after, [is='flynt-navigation-main'] .menu-footer .gform_wrapper .gform_footer[target=_blank]:focus::after, .gform_wrapper [is='flynt-navigation-main'] .menu-footer .gform_footer[target=_blank]:focus::after {
            background-image: url("../assets/icons/arrow-external-red.svg"); } }
  [is='flynt-navigation-main'] .menu-lang {
    margin: 0;
    padding: 0; }
    [is='flynt-navigation-main'] .menu-lang > * {
      padding-left: 18px;
      padding-right: 20px; }
    [is='flynt-navigation-main'] .menu-lang ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0; }
      [is='flynt-navigation-main'] .menu-lang ul > li::before {
        content: none; }
    [is='flynt-navigation-main'] .menu-lang a {
      border-left: 1px solid #878787;
      color: #878787;
      font-size: 1rem;
      font-weight: 400;
      min-width: 34px;
      padding: 8px 0 8px 10px;
      text-align: center;
      text-transform: uppercase; }
      [is='flynt-navigation-main'] .menu-lang a:hover {
        color: #003769; }
  [is='flynt-navigation-main'] .menu-search svg {
    width: 16px;
    position: relative;
    top: 11px; }
    [is='flynt-navigation-main'] .menu-search svg path {
      transition: fill 0.2s ease-out; }
  [is='flynt-navigation-main'] .menu-search a:hover svg path {
    fill: #003769; }

.scrollTop {
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 35px;
  opacity: 0;
  position: fixed;
  right: 0;
  transform: translateX(35px);
  transition: 0.3s ease;
  width: 35px;
  z-index: 100; }
  @media (min-width: 768px) {
    .scrollTop {
      bottom: 45px;
      height: 45px;
      transform: translateX(45px);
      width: 45px; } }
  .scrollTop.visible {
    opacity: 1;
    transform: translateX(0); }
  .scrollTop:hover, .scrollTop:focus {
    background-color: white; }
    .scrollTop:hover::before, .scrollTop:hover::after, .scrollTop:focus::before, .scrollTop:focus::after {
      background-color: #003769; }
  .scrollTop::before, .scrollTop::after {
    background: #0aa5af;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    margin: -1px 0 0 -6px;
    position: absolute;
    top: 50%;
    transition: background-color 0.3s ease;
    width: 12px; }
  .scrollTop::before {
    transform: translateX(4px) rotate(45deg); }
  .scrollTop::after {
    transform: translateX(-4px) rotate(-45deg); }

[is='flynt-navigation-sub-footer'] {
  background: #666;
  padding: 10px 0; }
  [is='flynt-navigation-sub-footer'] .menu {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0; }
    [is='flynt-navigation-sub-footer'] .menu > li::before {
      content: none; }
    @media (min-width: 768px) {
      [is='flynt-navigation-sub-footer'] .menu {
        justify-content: space-between;
        flex-direction: row; } }
    [is='flynt-navigation-sub-footer'] .menu-item {
      margin: 0; }
    [is='flynt-navigation-sub-footer'] .menu-link {
      color: #fff;
      font-weight: 400;
      font-size: 0.88rem; }
      [is='flynt-navigation-sub-footer'] .menu-link:after {
        background-image: none;
        display: none; }
      @media (max-width: 767px) {
        [is='flynt-navigation-sub-footer'] .menu-link {
          line-height: 1.85; } }
      @media (min-width: 1024px) {
        [is='flynt-navigation-sub-footer'] .menu-link:hover, [is='flynt-navigation-sub-footer'] .menu-link:focus {
          color: #0aa5af; } }

[is='flynt-press-teaser'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-press-teaser'] {
      margin: 68px 0; } }
  [is='flynt-press-teaser'] .preContent {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 1.5rem; }
    [is='flynt-press-teaser'] .preContent > :first-child:not(.overlineText) {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-press-teaser'] .preContent > :last-child:not(.overlineText) {
      margin-bottom: 0;
      padding-bottom: 0; }
    @media (min-width: 768px) {
      [is='flynt-press-teaser'] .preContent {
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 3.4rem; } }
    @media (min-width: 1280px) {
      [is='flynt-press-teaser'] .preContent {
        margin-left: 100px;
        margin-right: 100px; } }
  [is='flynt-press-teaser'] .swiper-container {
    padding-bottom: 20px;
    margin-bottom: -20px;
    width: 100%;
    padding-right: 10px;
    right: -10px;
    left: 5px; }
  @media (min-width: 360px) {
    [is='flynt-press-teaser'] .swiper-container {
      width: calc(100% - 30px); } }
  @media (min-width: 768px) {
    [is='flynt-press-teaser'] .swiper-container {
      width: calc(100% - 100px); } }
  @media (min-width: 1280px) {
    [is='flynt-press-teaser'] .swiper-container {
      width: calc(100% - 200px); } }
  [is='flynt-press-teaser'] .content {
    height: 100%; }
    [is='flynt-press-teaser'] .content .link-content {
      padding-right: 0;
      display: block; }
      [is='flynt-press-teaser'] .content .link-content::after, [is='flynt-press-teaser'] .content .link-content::before {
        content: none; }
      [is='flynt-press-teaser'] .content .link-content:hover .button-wrap,
      [is='flynt-press-teaser'] .content .link-content:hover .button,
      [is='flynt-press-teaser'] .content .link-content:hover .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-press-teaser'] .content .link-content:hover .gform_footer, [is='flynt-press-teaser'] .content .link-content:focus .button-wrap,
      [is='flynt-press-teaser'] .content .link-content:focus .button,
      [is='flynt-press-teaser'] .content .link-content:focus .gform_wrapper .gform_footer,
      .gform_wrapper [is='flynt-press-teaser'] .content .link-content:focus .gform_footer {
        background: #0aa5af; }
      [is='flynt-press-teaser'] .content .link-content:hover .content-image, [is='flynt-press-teaser'] .content .link-content:focus .content-image {
        transform: scale(1); }
    [is='flynt-press-teaser'] .content-image {
      transform: scale(1.3);
      transform-origin: 50% 50%;
      transition: transform 0.5s ease, opacity 0.5s ease; }
    [is='flynt-press-teaser'] .content-figure {
      background: #333;
      margin: 0;
      overflow: hidden; }
    [is='flynt-press-teaser'] .content-inner {
      position: relative;
      z-index: 2;
      padding-left: 34px;
      margin-top: -20px; }
      [is='flynt-press-teaser'] .content-inner .caption {
        background: #fff;
        padding: 20px 25px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15); }
        [is='flynt-press-teaser'] .content-inner .caption > :first-child:not(.overlineText) {
          margin-top: 0;
          padding-top: 0; }
        [is='flynt-press-teaser'] .content-inner .caption > :last-child:not(.overlineText) {
          margin-bottom: 0;
          padding-bottom: 0; }
        @media (min-width: 768px) {
          [is='flynt-press-teaser'] .content-inner .caption {
            padding: 20px 34px; } }
        [is='flynt-press-teaser'] .content-inner .caption-title {
          line-height: 1.2; }
      [is='flynt-press-teaser'] .content-inner .button-wrap {
        background: #003769;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0; }
  [is='flynt-press-teaser'] .swiper-navigation {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  [is='flynt-press-teaser'] .slider-button {
    z-index: 1;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 0; }
  [is='flynt-press-teaser'] .slider-button--prev {
    left: 0; }
  [is='flynt-press-teaser'] .slider-button--next {
    right: 0; }
  @media (min-width: 768px) {
    [is='flynt-press-teaser'] .slider-button {
      width: 50px;
      height: 50px; }
    [is='flynt-press-teaser'] .swiper-navigation.item--length2 {
      display: none; } }
  @media (min-width: 1280px) {
    [is='flynt-press-teaser'] .swiper-navigation {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      top: 0; } }

[is='flynt-slider-image-text'] {
  background: #333;
  margin: 68px 0;
  position: relative; }
  .mainContent [is='flynt-slider-image-text'].flyntComponent:first-child {
    margin-top: 50px; }
    @media (min-width: 768px) {
      .mainContent [is='flynt-slider-image-text'].flyntComponent:first-child {
        margin-top: 68px; } }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text'] {
      margin: 102px 0 135px; } }
  [is='flynt-slider-image-text'] .split-slider {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .split-slider {
        flex-direction: row; } }
    [is='flynt-slider-image-text'] .split-slider--invert {
      flex-direction: column-reverse; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text'] .split-slider--invert {
          flex-direction: row-reverse; } }
    [is='flynt-slider-image-text'] .split-slider > * {
      box-sizing: border-box;
      width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text'] .split-slider > * {
          width: 50%; } }
  [is='flynt-slider-image-text'] .slider {
    height: 100%;
    position: relative; }
  [is='flynt-slider-image-text'] .parallax {
    height: 100%;
    will-change: transform; }
    [is='flynt-slider-image-text'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
  [is='flynt-slider-image-text'] .slider-background {
    height: 100%;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
    [is='flynt-slider-image-text'] .slider-background .parallax {
      height: 100%;
      position: relative; }
    [is='flynt-slider-image-text'] .slider-background .figure-image {
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      width: auto; }
  [is='flynt-slider-image-text'] .slider-image {
    margin-left: -25px;
    margin-top: -34px;
    position: relative;
    z-index: 1; }
    @media (max-width: 767px) {
      [is='flynt-slider-image-text'] .slider-image .slider {
        margin-left: 0;
        max-width: 450px; } }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .slider-image {
        margin-bottom: 68px;
        margin-left: 0;
        margin-top: -34px; } }
    [is='flynt-slider-image-text'] .slider-image .figure {
      height: 100%;
      margin: 0;
      overflow: hidden; }
      [is='flynt-slider-image-text'] .slider-image .figure-image {
        font-family: 'object-fit: cover';
        margin: 0 auto;
        min-height: 100%;
        object-fit: cover;
        transform: scale(1.2);
        width: 100%; }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .split-slider--invert .slider-image {
      margin-bottom: -34px;
      margin-left: -25px;
      margin-top: 0; } }
  [is='flynt-slider-image-text'] .slider-caption {
    background: #fff;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: -34px; }
    @media (max-width: 767px) {
      [is='flynt-slider-image-text'] .slider-caption {
        margin-left: auto;
        margin-top: -25px;
        max-width: 450px; } }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .slider-caption {
        margin-top: 68px;
        position: relative;
        z-index: 0; } }
    [is='flynt-slider-image-text'] .slider-caption .caption {
      padding: 25px; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text'] .slider-caption .caption {
          padding: 34px; } }
      @media (min-width: 1280px) {
        [is='flynt-slider-image-text'] .slider-caption .caption {
          padding: 34px 68px 136px 34px; } }
      [is='flynt-slider-image-text'] .slider-caption .caption-content > :first-child:not(.overlineText) {
        margin-top: 0;
        padding-top: 0; }
      [is='flynt-slider-image-text'] .slider-caption .caption-content > :last-child:not(.overlineText) {
        margin-bottom: 0;
        padding-bottom: 0; }
      [is='flynt-slider-image-text'] .slider-caption .caption-content + .button, [is='flynt-slider-image-text'] .slider-caption .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-slider-image-text'] .slider-caption .caption-content + .gform_footer {
        margin-top: 20px;
        padding-right: 30px; }
        @media (min-width: 768px) {
          [is='flynt-slider-image-text'] .slider-caption .caption-content + .button, [is='flynt-slider-image-text'] .slider-caption .gform_wrapper .caption-content + .gform_footer, .gform_wrapper [is='flynt-slider-image-text'] .slider-caption .caption-content + .gform_footer {
            margin-top: 40px;
            padding-right: 35px; } }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-slider-image-text'] .slider-caption .caption-content h4,
        [is='flynt-slider-image-text'] .slider-caption .caption-content .h4 {
          font-size: 2.5rem; } }
      [is='flynt-slider-image-text'] .slider-caption .caption .overlineText {
        margin-bottom: 20px; }
        @media (min-width: 768px) and (max-width: 1023px) {
          [is='flynt-slider-image-text'] .slider-caption .caption .overlineText {
            font-size: 1.2rem; } }
  [is='flynt-slider-image-text'] .split-slider--invert .slider-caption {
    margin-bottom: -25px;
    margin-top: -34px; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .split-slider--invert .slider-caption {
        margin-bottom: -34px;
        margin-top: 68px; } }
  [is='flynt-slider-image-text'] .slider-button,
  [is='flynt-slider-image-text'] .slider-pages {
    display: none; }
  [is='flynt-slider-image-text'] .slider-pages {
    bottom: auto;
    color: #666;
    justify-content: flex-end;
    left: auto;
    line-height: 2.1;
    margin-right: 15px;
    min-height: 25px;
    position: absolute;
    right: 0;
    top: 100%;
    white-space: nowrap;
    width: auto; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .slider-pages {
        line-height: 2.4;
        min-height: 34px; } }
    @media (min-width: 768px) and (max-width: 1279px) {
      [is='flynt-slider-image-text'] .slider-pages {
        color: #fff;
        margin-right: 85px; } }
    @media (min-width: 1280px) {
      [is='flynt-slider-image-text'] .slider-pages {
        justify-content: flex-start;
        left: 100%;
        margin-left: 45px;
        margin-right: 0;
        right: auto; } }
  [is='flynt-slider-image-text'] .slider-button {
    outline: none;
    position: absolute;
    z-index: 3; }
    @media (max-width: 767px) {
      [is='flynt-slider-image-text'] .slider-button {
        height: 26px;
        margin-top: -1px;
        width: 25px; } }
    [is='flynt-slider-image-text'] .slider-button--prev {
      left: auto;
      right: -25px;
      top: 100%; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text'] .slider-button--prev {
          right: 34px; } }
      @media (min-width: 1280px) {
        [is='flynt-slider-image-text'] .slider-button--prev {
          right: 0; } }
    [is='flynt-slider-image-text'] .slider-button--next {
      left: auto;
      right: -50px;
      top: 100%; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text'] .slider-button--next {
          right: 0; } }
      @media (min-width: 1280px) {
        [is='flynt-slider-image-text'] .slider-button--next {
          left: 100%;
          right: auto; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .split-slider--invert .slider-pages {
      top: -25px; } }
  @media (min-width: 768px) and (max-width: 1279px) {
    [is='flynt-slider-image-text'] .split-slider--invert .slider-pages {
      justify-content: flex-start;
      left: 0;
      margin-left: 84px;
      margin-right: 0;
      right: auto; } }
  @media (min-width: 1280px) {
    [is='flynt-slider-image-text'] .split-slider--invert .slider-pages {
      left: auto;
      margin-left: 0;
      margin-right: 45px;
      right: 100%; } }
  [is='flynt-slider-image-text'] .split-slider--invert .slider-button--prev {
    left: auto;
    right: -25px;
    top: -25px; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .split-slider--invert .slider-button--prev {
        left: 0;
        right: auto;
        top: 100%; } }
    @media (min-width: 1280px) {
      [is='flynt-slider-image-text'] .split-slider--invert .slider-button--prev {
        left: auto;
        right: 100%; } }
  [is='flynt-slider-image-text'] .split-slider--invert .slider-button--next {
    left: auto;
    right: -50px;
    top: -25px; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'] .split-slider--invert .slider-button--next {
        left: 34px;
        right: auto;
        top: 100%; } }
    @media (min-width: 1280px) {
      [is='flynt-slider-image-text'] .split-slider--invert .slider-button--next {
        left: 0;
        right: auto; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .split-slider--invert .slider-caption .slider-button--prev {
      bottom: 0;
      top: auto; }
    [is='flynt-slider-image-text'] .split-slider--invert .slider-caption .slider-button--next {
      bottom: 0;
      top: auto; }
    [is='flynt-slider-image-text'] .split-slider--invert .slider-caption .slider-pages {
      bottom: 0;
      top: auto; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .split-slider:not(.split-slider--invert) .slider-button {
      background-color: #fff; }
      [is='flynt-slider-image-text'] .split-slider:not(.split-slider--invert) .slider-button svg path {
        stroke: #0aa5af; }
      [is='flynt-slider-image-text'] .split-slider:not(.split-slider--invert) .slider-button--white {
        background-color: #0aa5af; }
        [is='flynt-slider-image-text'] .split-slider:not(.split-slider--invert) .slider-button--white svg path {
          stroke: #fff; } }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text'] .slider-caption .slider-button,
    [is='flynt-slider-image-text'] .slider-caption .slider-pages {
      display: none; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .slider-caption {
      position: relative;
      z-index: 2; }
      [is='flynt-slider-image-text'] .slider-caption .slider-button--prev {
        left: auto;
        right: 0;
        top: 0; }
      [is='flynt-slider-image-text'] .slider-caption .slider-button--next {
        left: auto;
        right: -25px;
        top: 0; }
      [is='flynt-slider-image-text'] .slider-caption .slider-pages {
        left: auto;
        margin-right: 40px;
        right: 0;
        top: 0; }
      [is='flynt-slider-image-text'] .slider-caption .swiper-container-initialized ~ .slider-button {
        display: flex; }
      [is='flynt-slider-image-text'] .slider-caption .swiper-container-initialized ~ .slider-pages {
        display: block; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text'] .slider-image .slider-button,
    [is='flynt-slider-image-text'] .slider-image .slider-pages {
      display: none; } }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text'] .slider-image .swiper-container-initialized ~ .slider-button {
      display: flex; }
    [is='flynt-slider-image-text'] .slider-image .swiper-container-initialized ~ .slider-pages {
      display: block; } }
  [is='flynt-slider-image-text']:not(.flyntComponent--paddingOn) + .flyntComponent--paddingOn {
    margin: -34px 0 68px; }
  [is='flynt-slider-image-text'].flyntComponent--paddingOn {
    margin: 30px 0;
    padding: 68px 0; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text'].flyntComponent--paddingOn {
        margin: 68px 0; } }
    .mainContent [is='flynt-slider-image-text'].flyntComponent--paddingOn:first-child {
      margin-top: 25px;
      padding: 68px 0; }
      @media (min-width: 768px) {
        .mainContent [is='flynt-slider-image-text'].flyntComponent--paddingOn:first-child {
          margin-top: 34px; } }

[is='flynt-slider-image-text-modal'] {
  margin: 30px 0; }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text-modal'] {
      margin: 68px 0; } }
  [is='flynt-slider-image-text-modal'] .centerMaxWidthContainer {
    max-width: 1224px; }
  [is='flynt-slider-image-text-modal'] .split-slider {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text-modal'] .split-slider {
        align-items: flex-end;
        flex-direction: row; } }
  [is='flynt-slider-image-text-modal'] .slider {
    height: 100%;
    position: relative; }
    [is='flynt-slider-image-text-modal'] .slider-image, [is='flynt-slider-image-text-modal'] .slider-caption {
      width: 100%; }
    [is='flynt-slider-image-text-modal'] .slider-image {
      cursor: pointer; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-image {
          width: calc(100% - 204px); } }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text-modal'] .slider-caption {
        width: 238px; } }
  [is='flynt-slider-image-text-modal'] .parallax {
    will-change: transform; }
    [is='flynt-slider-image-text-modal'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
  [is='flynt-slider-image-text-modal'] .slider-image {
    position: relative; }
    [is='flynt-slider-image-text-modal'] .slider-image .figure {
      height: 100%;
      margin: 0;
      overflow: hidden; }
      [is='flynt-slider-image-text-modal'] .slider-image .figure-image {
        margin: 0 auto;
        min-height: 100%;
        object-fit: cover;
        transform: scale(1.2);
        width: 100%; }
    [is='flynt-slider-image-text-modal'] .slider-image .button-hasIcon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3; }
      @media (min-width: 768px) and (max-width: 1025px) {
        [is='flynt-slider-image-text-modal'] .slider-image .button-hasIcon {
          left: 0;
          right: auto; } }
  [is='flynt-slider-image-text-modal'] .slider-caption {
    background: #fff;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15);
    margin-left: 15px;
    margin-top: -15px;
    max-width: calc(100% - 130px);
    position: relative;
    z-index: 2; }
    @media (min-width: 480px) {
      [is='flynt-slider-image-text-modal'] .slider-caption {
        margin-left: 25px;
        margin-top: -25px;
        max-width: calc(100% - 145px); } }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text-modal'] .slider-caption {
        margin-bottom: -34px;
        margin-left: -34px;
        margin-top: 0;
        max-width: none; } }
    [is='flynt-slider-image-text-modal'] .slider-caption .caption {
      padding: 15px; }
      @media (min-width: 480px) {
        [is='flynt-slider-image-text-modal'] .slider-caption .caption {
          padding: 25px; } }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-caption .caption {
          padding: 34px 34px 102px; } }
  [is='flynt-slider-image-text-modal'] .slider-button,
  [is='flynt-slider-image-text-modal'] .slider-pages {
    display: none; }
  [is='flynt-slider-image-text-modal'] .slider-pages {
    bottom: auto;
    color: #666;
    justify-content: flex-end;
    left: auto;
    line-height: 2.1;
    margin-right: 15px;
    min-height: 25px;
    position: absolute;
    right: 50px;
    top: 100%;
    white-space: nowrap;
    width: auto;
    z-index: 3; }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text-modal'] .slider-pages {
        bottom: 34px;
        justify-content: flex-start;
        left: 34px;
        line-height: 2.4;
        margin-left: 15px;
        margin-right: 0;
        min-height: 34px;
        right: auto;
        top: auto; } }
  [is='flynt-slider-image-text-modal'] .slider-button {
    outline: none;
    position: absolute;
    top: 100%;
    z-index: 3; }
    @media (max-width: 767px) {
      [is='flynt-slider-image-text-modal'] .slider-button {
        height: 26px;
        width: 25px; }
        [is='flynt-slider-image-text-modal'] .slider-button:not([data-slider-modal-button]) {
          background-color: #fff;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.15); }
          [is='flynt-slider-image-text-modal'] .slider-button:not([data-slider-modal-button]) svg path {
            stroke: #0aa5af; }
        [is='flynt-slider-image-text-modal'] .slider-button--white:not([data-slider-modal-button]) {
          background-color: #0aa5af;
          box-shadow: none !important; }
          [is='flynt-slider-image-text-modal'] .slider-button--white:not([data-slider-modal-button]) svg path {
            stroke: #fff; } }
    @media (min-width: 768px) {
      [is='flynt-slider-image-text-modal'] .slider-button {
        bottom: 34px;
        top: auto; } }
    [is='flynt-slider-image-text-modal'] .slider-button--white {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); }
    [is='flynt-slider-image-text-modal'] .slider-button--prev {
      left: -25px; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-button--prev {
          left: -34px; } }
    [is='flynt-slider-image-text-modal'] .slider-button--next {
      left: 0; }
  [is='flynt-slider-image-text-modal'] .swiper-container-initialized ~ .slider-button {
    display: flex; }
  [is='flynt-slider-image-text-modal'] .swiper-container-initialized ~ .slider-pages {
    display: block; }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text-modal'] .slider-image .slider-button,
    [is='flynt-slider-image-text-modal'] .slider-image .slider-pages {
      display: none; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text-modal'] .slider-image {
      position: relative;
      z-index: 2; }
      [is='flynt-slider-image-text-modal'] .slider-image .slider-button--prev {
        left: auto;
        right: 25px;
        top: 100%; }
      [is='flynt-slider-image-text-modal'] .slider-image .slider-button--next {
        left: auto;
        right: 0;
        top: 100%; }
      [is='flynt-slider-image-text-modal'] .slider-image .slider-pages {
        left: auto;
        margin-right: 40px;
        right: 25px;
        top: 100%; }
      [is='flynt-slider-image-text-modal'] .slider-image .swiper-container-initialized ~ .slider-button {
        display: flex; }
      [is='flynt-slider-image-text-modal'] .slider-image .swiper-container-initialized ~ .slider-pages {
        display: block; } }
  @media (max-width: 767px) {
    [is='flynt-slider-image-text-modal'] .slider-caption .slider-button,
    [is='flynt-slider-image-text-modal'] .slider-caption .slider-pages {
      display: none; } }
  @media (min-width: 768px) {
    [is='flynt-slider-image-text-modal'] .slider-caption .swiper-container-initialized ~ .slider-button {
      display: flex; }
    [is='flynt-slider-image-text-modal'] .slider-caption .swiper-container-initialized ~ .slider-pages {
      display: block; } }
  [is='flynt-slider-image-text-modal'] .slider-modal {
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; }
    [is='flynt-slider-image-text-modal'] .slider-modal-inner {
      background: none;
      max-height: 100%;
      overflow-y: auto;
      touch-action: auto;
      width: 100%; }
    [is='flynt-slider-image-text-modal'] .slider-modal .button-closeModal {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10; }
    [is='flynt-slider-image-text-modal'] .slider-modal .centerMaxWidthContainer {
      max-width: 100%; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-modal .centerMaxWidthContainer {
          max-width: 90vw; } }
      @media (min-width: 1920px) {
        [is='flynt-slider-image-text-modal'] .slider-modal .centerMaxWidthContainer {
          max-width: 1560px; } }
    [is='flynt-slider-image-text-modal'] .slider-modal .slider-item {
      padding-bottom: 26px;
      padding-top: 34px; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-modal .slider-item {
          padding-bottom: 34px;
          padding-top: 34px; } }
    [is='flynt-slider-image-text-modal'] .slider-modal .sliderModalCaption .slider-item {
      padding-top: 0; }
    [is='flynt-slider-image-text-modal'] .slider-modal .figure {
      margin-bottom: 0; }
    [is='flynt-slider-image-text-modal'] .slider-modal .modal-caption {
      padding-left: 25px;
      padding-right: 105px; }
      @media (min-width: 768px) {
        [is='flynt-slider-image-text-modal'] .slider-modal .modal-caption {
          padding: 5px 0 0; } }
    [is='flynt-slider-image-text-modal'] .slider-modal .swiper-container-initialized .slider-modal-nav .slider-button {
      display: flex; }
    [is='flynt-slider-image-text-modal'] .slider-modal .swiper-container-initialized .slider-modal-nav .slider-pages {
      display: block; }
    [is='flynt-slider-image-text-modal'] .slider-modal-nav {
      align-items: center;
      bottom: 0;
      display: flex;
      position: absolute;
      right: 0; }
      [is='flynt-slider-image-text-modal'] .slider-modal-nav .slider-button {
        position: static; }
      [is='flynt-slider-image-text-modal'] .slider-modal-nav .slider-pages {
        color: #fff;
        margin: 0 15px 0 0;
        position: static; }
    @media (max-width: 767px) {
      [is='flynt-slider-image-text-modal'] .slider-modal .slider {
        margin-left: -25px;
        margin-right: -25px; } }

[is='flynt-slider-testimonial'] {
  background: #333;
  margin: 30px 0;
  padding: 30px 0;
  position: relative; }
  [is='flynt-slider-testimonial'].flyntComponent--whiteBackground {
    background: #fff; }
  @media (min-width: 768px) {
    [is='flynt-slider-testimonial'] {
      margin: 102px 0 68px;
      padding: 68px 0; } }
  [is='flynt-slider-testimonial'] .testimonial {
    align-items: center;
    display: flex;
    flex-direction: column; }
    [is='flynt-slider-testimonial'] .testimonial > * {
      box-sizing: border-box;
      width: 100%; }
    @media (min-width: 1024px) {
      [is='flynt-slider-testimonial'] .testimonial {
        flex-direction: row; }
        [is='flynt-slider-testimonial'] .testimonial > *:first-child {
          padding-right: 34px;
          width: 42%; }
        [is='flynt-slider-testimonial'] .testimonial > *:last-child {
          width: 58%; } }
    @media (min-width: 1280px) {
      [is='flynt-slider-testimonial'] .testimonial > *:first-child {
        padding-right: 68px;
        width: 47%; }
      [is='flynt-slider-testimonial'] .testimonial > *:last-child {
        width: 53%; } }
    [is='flynt-slider-testimonial'] .testimonial-type {
      align-items: center;
      background-color: #003769;
      display: flex;
      height: 60px;
      justify-content: center;
      min-width: 60px;
      width: 60px; }
      [is='flynt-slider-testimonial'] .testimonial-type svg {
        width: 34px; }
      @media (min-width: 768px) {
        [is='flynt-slider-testimonial'] .testimonial-type {
          height: 136px;
          min-width: 136px;
          width: 136px; }
          [is='flynt-slider-testimonial'] .testimonial-type svg {
            width: auto; } }
    [is='flynt-slider-testimonial'] .testimonial-title {
      background: none;
      position: relative;
      z-index: 2; }
      [is='flynt-slider-testimonial'] .testimonial-title > *:first-child {
        padding-top: 0; }
      [is='flynt-slider-testimonial'] .testimonial-title > *:last-child {
        margin-bottom: 0; }
      @media (min-width: 1024px) and (max-width: 1279px) {
        [is='flynt-slider-testimonial'] .testimonial-title h1,
        [is='flynt-slider-testimonial'] .testimonial-title h2,
        [is='flynt-slider-testimonial'] .testimonial-title h3,
        [is='flynt-slider-testimonial'] .testimonial-title h4 {
          font-size: 2.5rem; } }
      @media (max-width: 767px) {
        [is='flynt-slider-testimonial'] .testimonial-title {
          margin-bottom: 25px; } }
      @media (min-width: 768px) and (max-width: 1023px) {
        [is='flynt-slider-testimonial'] .testimonial-title {
          margin-bottom: 40px; } }
  [is='flynt-slider-testimonial'] .split-slider {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      [is='flynt-slider-testimonial'] .split-slider {
        align-items: flex-end;
        flex-direction: row;
        max-width: 740px; } }
    [is='flynt-slider-testimonial'] .split-slider > * {
      box-sizing: border-box;
      flex-grow: 1; }
      [is='flynt-slider-testimonial'] .split-slider > *:first-child {
        align-items: flex-end;
        width: 180px; }
        @media (min-width: 768px) {
          [is='flynt-slider-testimonial'] .split-slider > *:first-child {
            width: 204px; } }
      @media (min-width: 768px) {
        [is='flynt-slider-testimonial'] .split-slider > *:last-child {
          width: calc(100% - 204px); } }
  [is='flynt-slider-testimonial'] .slider {
    position: relative;
    width: 100%; }
  [is='flynt-slider-testimonial'] .parallax {
    will-change: transform; }
    [is='flynt-slider-testimonial'] .parallax-elem-1 {
      transform: translateY(var(--slideDown)); }
  [is='flynt-slider-testimonial'] .slider-background {
    height: 100%;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0; }
    [is='flynt-slider-testimonial'] .slider-background .parallax {
      height: 100%;
      position: relative; }
    [is='flynt-slider-testimonial'] .slider-background .figure-image {
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.2);
      width: auto; }
  [is='flynt-slider-testimonial'] .slider-image {
    display: flex;
    margin-left: 25px;
    position: relative; }
    @media (min-width: 768px) {
      [is='flynt-slider-testimonial'] .slider-image {
        align-items: flex-end;
        flex-direction: column;
        margin-left: 0; } }
    [is='flynt-slider-testimonial'] .slider-image .figure {
      height: 100%;
      margin: 0;
      overflow: hidden; }
      [is='flynt-slider-testimonial'] .slider-image .figure-image {
        margin: 0 auto;
        min-height: 100%;
        object-fit: cover;
        width: 100%; }
  [is='flynt-slider-testimonial'] .slider-caption {
    background: #fff;
    box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.15); }
    @media (min-width: 1024px) {
      [is='flynt-slider-testimonial'] .slider-caption {
        margin-top: -102px;
        min-height: 435px; } }
    [is='flynt-slider-testimonial'] .slider-caption .caption {
      padding: 50px 25px; }
      @media (min-width: 768px) {
        [is='flynt-slider-testimonial'] .slider-caption .caption {
          padding: 102px 34px; } }
      @media (min-width: 1280px) {
        [is='flynt-slider-testimonial'] .slider-caption .caption {
          padding: 102px 68px; } }
      [is='flynt-slider-testimonial'] .slider-caption .caption-content > :first-child:not(.overlineText) {
        margin-top: 0;
        padding-top: 0; }
      [is='flynt-slider-testimonial'] .slider-caption .caption-content > :last-child:not(.overlineText) {
        margin-bottom: 0;
        padding-bottom: 0; }
      [is='flynt-slider-testimonial'] .slider-caption .caption-content + * {
        margin-top: 15px; }
        @media (min-width: 768px) {
          [is='flynt-slider-testimonial'] .slider-caption .caption-content + * {
            margin-top: 25px; } }
      [is='flynt-slider-testimonial'] .slider-caption .caption-author {
        margin-bottom: 0;
        text-transform: uppercase; }
  [is='flynt-slider-testimonial'] .slider-button,
  [is='flynt-slider-testimonial'] .slider-pages {
    display: none; }
  [is='flynt-slider-testimonial'] .slider-pages {
    bottom: auto;
    bottom: 0;
    color: #666;
    justify-content: flex-end;
    left: auto;
    line-height: 2.1;
    margin-right: 15px;
    min-height: 25px;
    position: absolute;
    right: 25px;
    white-space: nowrap;
    width: auto; }
    @media (min-width: 768px) {
      [is='flynt-slider-testimonial'] .slider-pages {
        bottom: 34px;
        line-height: 2.4;
        margin-right: 45px;
        min-height: 34px;
        right: 0; } }
  [is='flynt-slider-testimonial'] .slider-button {
    outline: none;
    position: absolute;
    z-index: 3; }
    @media (max-width: 767px) {
      [is='flynt-slider-testimonial'] .slider-button {
        height: 26px;
        margin-top: -1px;
        width: 25px; } }
    [is='flynt-slider-testimonial'] .slider-button--prev {
      bottom: 0;
      right: 0; }
      @media (min-width: 768px) {
        [is='flynt-slider-testimonial'] .slider-button--prev {
          bottom: 34px;
          right: 0; } }
    [is='flynt-slider-testimonial'] .slider-button--next {
      bottom: 0;
      right: -25px; }
      @media (min-width: 768px) {
        [is='flynt-slider-testimonial'] .slider-button--next {
          bottom: 34px;
          right: -34px; } }
  [is='flynt-slider-testimonial'].flyntComponent--whiteBackground .slider-button--white {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); }
  [is='flynt-slider-testimonial'] .swiper-container-initialized ~ .slider-button {
    display: flex; }
  [is='flynt-slider-testimonial'] .swiper-container-initialized ~ .slider-pages {
    display: block; }
